import React, {ReactNode} from "react";
import Images from "../../assets/images";


interface NavbarEntries {
    name: string,
    to: string,
    icon: ReactNode,
}

export const contentNavbarEntries: readonly NavbarEntries[] = [

    {
        name: "profilo",
        to: "/profile",
        icon: <img src={Images.personWhite} alt={'profilo'} style={{width: '24px', height: '24px'}}/>
    },
    {
        name: "listino",
        to: "/catalogue",
        icon: <img src={Images.navbar_listino} alt={'profilo'} style={{width: '24px', height: '24px'}}/>
    },
    {
        name: "preventivi",
        to: "/estimate",
        icon: <img src={Images.estimatesWhite} alt={'preventivi'} style={{width: '24px', height: '24px'}}/>
    },
    {
        name: "consuntivi",
        to: "/final_balance",
        icon: <img src={Images.consuntiviWhite} alt={'consuntivi'} style={{width: '24px', height: '24px'}}/>
    },
    {
        name: "clienti",
        to: "/client",
        icon: <img src={Images.people} alt={'clienti'} style={{width: '24px', height: '24px'}}/>
    },
    {
        name: "assistenza",
        to: "/user",
        icon: <img src={Images.headphone} alt={'assistenza'} style={{width: '24px', height: '24px'}}/>
    },
    {
        name: "abbonamento",
        to: "/user",
        icon: <img src={Images.subscription} alt={'abbonamento'} style={{width: '24px', height: '24px'}}/>
    }

];


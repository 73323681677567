import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {AxiosError} from "axios";
import {IUserField} from "../../../utils/interfaces/Estimate/IUserField";
import useApi from "../../../utils/custom-hooks/api-hooks/useApi";


const useApiGetEstimateUserfields = (estimate_id: number | undefined): UseQueryResult<IUserField[], AxiosError> => {
    const {get} = useApi()

    async function doGet(estimate_id: number | undefined): Promise<IUserField[]> {
        try {
            const response = await get(`/estimates/${estimate_id}/usersfields`);
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetEstimateUserfields", estimate_id],
        queryFn: () => doGet(estimate_id),
        enabled: !!estimate_id
    })
};

export default useApiGetEstimateUserfields

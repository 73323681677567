import React from "react";
import {useQueryClient} from "@tanstack/react-query";
import {IProcessing} from "../../../utils/interfaces/Catalogue/IProcessing";
import {useApp} from "../../../utils/global-contexts/AppContext";
import CategorySelect from "../Category/CategorySelect";
import {ICategory} from "../../../utils/interfaces/Catalogue/ICategory";
import useApiUpdateProcessing from "./api-hooks/useApiUpdateProcessing";
import {Box, Grid} from "@mui/material";
import UndoButton from "../../../ui/Buttons/UndoButton";

interface Props {
    onClose: () => void
    obj: IProcessing | undefined
}

const ProcessingCategoryChanger: React.FC<Props> = ({onClose, obj}) => {
    const updateMutation = useApiUpdateProcessing();
    const queryClient = useQueryClient();
    const {userId} = useApp()


    const handleClick = async (category: ICategory | null) => {
        console.log(category)

        if (category === null) {
            return
        }

        const payload = {
            category: category,

        };

        try {
            await updateMutation.mutateAsync({id: obj?.id, data: payload});
        } catch (error) {
        }
        onClose();
        await queryClient.invalidateQueries({queryKey: ['useApiGetUserEstimates', userId]});

    };

    return (
        <>
            {onClose &&
                <Grid item xs={12} style={{textAlign: 'center'}}>
                    <Box className={'mt-2 gap-x-2 mb-2'}>
                        <UndoButton onClose={onClose}/>
                    </Box>
                </Grid>}
            
            <CategorySelect
                obj={undefined}
                className={''}
                onChange={handleClick}
                label={'select category'}
            />
        </>
    );
};

export default ProcessingCategoryChanger;

import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {AxiosError} from "axios";
import useApi from "../../../utils/custom-hooks/api-hooks/useApi";
import {useApp} from "../../../utils/global-contexts/AppContext";
import {IClient} from "../../../utils/interfaces/Client/IClient";


const useApiUpdateClient = (): UseMutationResult<IClient, AxiosError, {
    id: number | undefined;
    data: any
}, unknown> => {
    const queryClient = useQueryClient();
    const {userId, selectedEstimate} = useApp()

    const {putEncoded, put} = useApi()

    async function doMutation(pk: number | undefined,
                              data: any): Promise<IClient> {
        try {
            const response = await put(`/clients/${pk}`, data);
            return response.data.data as IClient;
        } catch (error) {
            throw error;
        }
    }

    return useMutation<IClient, AxiosError, {
        id: number | undefined;
        data: any
    }, unknown>({
        mutationFn: async ({id, data}) => {
            return await doMutation(id, data);
        },
        onSuccess: async (data) => {
            await queryClient.invalidateQueries({queryKey: ['useApiGetUserClients', userId]});
            await queryClient.invalidateQueries({queryKey: ['useApiGetEstimate', selectedEstimate?.id]});
        },
    });
};


export default useApiUpdateClient;

import React, {ReactNode} from 'react';
import {Box, Modal} from '@mui/material';
import {useTheme} from "@mui/material/styles";

interface MuiModalProps {
    top: string;
    left: string;
    width: number | string;
    height?: number;
    open: boolean | undefined;
    onClose: () => void;
    children: ReactNode;
    disableBackdropClick?: boolean;
}

const MuiModal: React.FC<MuiModalProps> = ({
                                               top,
                                               left,
                                               width,
                                               height = undefined,
                                               open,
                                               onClose,
                                               children,
                                               disableBackdropClick = false
                                           }) => {
    const theme = useTheme();
    const style = {
        position: 'absolute' as const,
        top,
        left,
        width,
        height,
        transform: 'translate(-50%, -50%)',
        background: theme.palette.background.default,
        border: `2px solid ${theme.palette.primary.main}`,
        boxShadow: 24,
        borderRadius: '4px 4px 4px 4px',
        p: 4,
    };

    const handleClose = () => {
        if (!disableBackdropClick) {
            onClose();
        }
    };

    return (
        <Modal
            open={!!open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                {children}
            </Box>
        </Modal>
    );
};

export default MuiModal;

//
// // example:
//     <MuiModal
//         top="50%"
//         left="50%"
//         width={600}
//         open={isMovementsFormOpen}
//         onClose={handleClose}
//     >
//         <ChemicalProductsMovementsTable
//             product_id={selectedRowId}
//             onClose={() => setIsMovementsFormOpen(false)}
//         />
//     </MuiModal>

import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Box, CircularProgress, TextField} from "@mui/material";
import {Add, Edit} from "@mui/icons-material";
import {useTheme} from "@mui/material/styles";
import {IClient} from "../../utils/interfaces/Client/IClient";
import useApiGetUserClients from "./api-hooks/useApiGetUserClients";
import {useApp} from "../../utils/global-contexts/AppContext";
import MuiAutocomplete from "../../ui/MuiAutocomplete";
import MRTMuiButton from "../../ui/MuiReactTable/MRTMuiButton";
import ClientForm from "./ClientForm";
import MuiModal from "../../ui/MuiModals/MuiModal";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ClientTable from "./ClientTable";
import useApiGetUserClient from "./api-hooks/useApiGetUserClient";


interface Props {
    obj: IClient | undefined,
    onChange: (obj: IClient | null) => void,
    className: string,
    label?: string,
    required?: boolean
}

const ClientSelect = ({obj: initialObj, onChange, label, required}: Props) => {
    const theme = useTheme();
    const {userId} = useApp()
    const {t} = useTranslation();
    const [objs, setObjs] = useState<IClient[]>([]);
    const [obj, setObj] = useState<IClient | null | undefined>(initialObj);

    const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false)
    const [isListTableOpen, setIsListTableOpen] = useState<boolean>(false)


    const {
        data,
        isLoading,
        error,
    } = useApiGetUserClients(userId);
    if (error) {

    }

    useEffect(() => {
        setObj(initialObj);
    }, [initialObj]);


    useEffect(() => {
        if (data) {
            setObjs(data)
        }
    }, [data]);

    const {
        data: detailedObj,
    } = useApiGetUserClient(userId, obj?.id);

    const handleOnChange = (event: React.SyntheticEvent, newValue: IClient | null) => {
        onChange(newValue);
    };

    const handleChange = (obj: IClient | undefined) => {
        setObj(obj);
        onChange(obj ? obj : null)
    }


    return (
        isLoading ? (
            <CircularProgress/>
        ) : (
            <>
                <Box sx={{alignItems: 'center', width: '100%'}}>
                    <MuiAutocomplete
                        id="client-select"
                        options={objs}
                        getOptionLabel={(option) => option.type === "private" ? option.name : option.company_name}
                        value={detailedObj ? detailedObj : null}
                        onChange={handleOnChange}
                        isOptionEqualToValue={(option, value) => option.id === value?.id && option.name === value?.name}
                        renderInput={(params) => (
                            <TextField
                                sx={{textTransform: 'capitalize'}}
                                {...params}
                                label={label || t('client')}
                                required={required}
                            />
                        )}
                    >
                        <MRTMuiButton
                            icon={<FormatListBulletedIcon sx={{color: theme.palette.primary.main}}/>}
                            onClick={() => setIsListTableOpen(true)}
                            small={true}
                            label={'show client list'}
                        />
                        <MRTMuiButton
                            small={true}
                            icon={obj ?
                                <Edit sx={{color: theme.palette.primary.main}}/>
                                :
                                <Add sx={{color: theme.palette.primary.main}}/>}
                            onClick={() => {
                                setIsEditFormOpen(true)
                            }} label={obj ? 'edit client' : 'add client'}/>
                    </MuiAutocomplete>
                </Box>

                <MuiModal
                    top="50%"
                    left="50%"
                    width={'100vH'}
                    open={isEditFormOpen}
                    onClose={() => setIsEditFormOpen(false)}
                >
                    <ClientForm objToEdit={obj ? obj : null}
                                onClose={() => setIsEditFormOpen(false)}/>
                </MuiModal>

                <MuiModal top="50%" left="50%" width={'120vh'} open={isListTableOpen}
                          onClose={() => setIsListTableOpen(false)}
                          disableBackdropClick={true}
                >
                    <ClientTable
                        alreadySelectedObj={obj ? obj : undefined}
                        onSelect={handleChange}
                        onClose={() => setIsListTableOpen(false)}
                    />
                </MuiModal>
            </>
        )
    );
}

export default ClientSelect;

import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Box, CircularProgress, TextField} from "@mui/material";
import {Add, Edit} from "@mui/icons-material";
import {useTheme} from "@mui/material/styles";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import {ICategory} from "../../../utils/interfaces/Catalogue/ICategory";
import useApiGetCatalogue from "../api-hooks/useApiGetCatalogue";
import MuiAutocomplete from "../../../ui/MuiAutocomplete";
import MRTMuiButton from "../../../ui/MuiReactTable/MRTMuiButton";
import MuiModal from "../../../ui/MuiModals/MuiModal";
import CategoryTable from "./CategoryTable";


interface Props {
    obj: ICategory | undefined,
    onChange: (obj: ICategory | null) => void,
    className: string,
    label?: string,
    required?: boolean
}

const CategorySelect = ({obj: initialObj, onChange, label, required}: Props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const [objs, setObjs] = useState<ICategory[]>([]);
    const [obj, setObj] = useState<ICategory | null | undefined>(initialObj);

    const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false)
    const [isListTableOpen, setIsListTableOpen] = useState<boolean>(false)


    const {
        data,
        isLoading,
        error,
    } = useApiGetCatalogue();
    if (error) {

    }

    useEffect(() => {
        setObj(initialObj);
    }, [initialObj]);


    useEffect(() => {
        if (data) {
            setObjs(data.categories)
        }
    }, [data]);


    const handleOnChange = (event: React.SyntheticEvent, newValue: ICategory | null) => {
        onChange(newValue);
    };

    const handleChange = (obj: ICategory | undefined) => {
        setObj(obj);
        onChange(obj ? obj : null)
    }


    return (
        isLoading ? (
            <CircularProgress/>
        ) : (
            <>
                <Box sx={{alignItems: 'center', width: '100%'}}>
                    <MuiAutocomplete
                        id="category-select"
                        options={objs}
                        getOptionLabel={(option) => option.label}
                        value={initialObj ? initialObj : null}
                        onChange={handleOnChange}
                        isOptionEqualToValue={(option, value) => option.id === value?.id && option.label === value?.label}
                        renderInput={(params) => (
                            <TextField
                                sx={{textTransform: 'capitalize'}}
                                {...params}
                                label={label || t('select category')}
                                required={required}
                            />
                        )}
                    >
                        <MRTMuiButton
                            icon={<FormatListBulletedIcon sx={{color: theme.palette.primary.main}}/>}
                            onClick={() => setIsListTableOpen(true)}
                            small={true}
                            label={'show category list'}
                        />
                        <MRTMuiButton
                            small={true}
                            icon={obj ?
                                <Edit sx={{color: theme.palette.primary.main}}/>
                                :
                                <Add sx={{color: theme.palette.primary.main}}/>}
                            onClick={() => {
                                setIsEditFormOpen(true)
                            }} label={obj ? 'edit category' : 'add category'}/>
                    </MuiAutocomplete>
                </Box>

                {/*<MuiModal*/}
                {/*    top="50%"*/}
                {/*    left="50%"*/}
                {/*    width={'100vH'}*/}
                {/*    open={isEditFormOpen}*/}
                {/*    onClose={() => setIsEditFormOpen(false)}*/}
                {/*>*/}
                {/*    <ClientForm objToEdit={obj ? obj : null}*/}
                {/*                onClose={() => setIsEditFormOpen(false)}/>*/}
                {/*</MuiModal>*/}

                <MuiModal top="50%" left="50%" width={'120vh'} open={isListTableOpen}
                          onClose={() => setIsListTableOpen(false)}
                          disableBackdropClick={true}
                >
                    <CategoryTable
                        alreadySelectedObj={obj ? obj : undefined}
                        onSelect={handleChange}
                        onClose={() => setIsListTableOpen(false)}
                    />
                </MuiModal>
            </>
        )
    );
}

export default CategorySelect;

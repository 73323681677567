import {useTheme} from "@mui/material/styles";
import {useQueryClient} from "@tanstack/react-query";
import {useApp} from "../../utils/global-contexts/AppContext";
import React, {useEffect, useMemo, useState} from "react";
import useCustomCellRenderer from "./useCustomCellRenderer";
import {
    MaterialReactTable,
    MRT_ActionMenuItem,
    MRT_ColumnDef,
    MRT_RowSelectionState,
    useMaterialReactTable
} from "material-react-table";
import {
    getMuiTableBodyCellProps,
    getMuiTableBodyRowProps,
    getMuiTableHeadCellProps,
    getMuiTablePaperProps
} from "../../ui/MuiReactTable/MuiReactTableStyles";
import {Avatar, Box, CircularProgress} from "@mui/material";
import MuiReadOnlyBoxBody from "../../ui/MuiDisplayData/MuiReadOnlyBoxBody";
import MRTMuiButton from "../../ui/MuiReactTable/MRTMuiButton";
import Images from "../../assets/images";
import {Add, Delete, Edit} from "@mui/icons-material";
import {formatDateTime} from "../../logics/helpers";
import {useTranslation} from "react-i18next";
import MuiAlertDialog from "../../ui/MuiAlertDialog";
import useApiCreateFinalBalance from "./api-hooks/useApiCreateFinalBalance";
import useApiDeleteFinalBalance from "./api-hooks/useApiDeleteFinalBalance";
import useApiGetUserFinalBalances from "./api-hooks/useApiGetUserFinalBalances";
import {IFinalBalance} from "../../utils/interfaces/FinalBalance/IFinalBalance";
import FinalBalanceForm from "./FinalBalanceForm";


interface Props {
}


const FinalBalanceTable = ({}: Props) => {
    const theme = useTheme();
    const {t} = useTranslation()
    const {userId, setSelectedFinalBalance, selectedFinalBalance} = useApp();
    const queryClient = useQueryClient();

    const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
    const [selectedRow, setSelectedRow] = useState<IFinalBalance | undefined>()
    const [rows, setRows] = useState<IFinalBalance[] | undefined>()

    const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false)


    const createMutation = useApiCreateFinalBalance();
    const handleCreateEstimate = async () => {
        try {
            const payload = {
                type: "finalbalance",
                name: " ",
                user: userId,
            };

            await createMutation.mutateAsync({data: payload});
        } catch (error) {
        }
    }
    const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const deleteMutation = useApiDeleteFinalBalance();
    const {mutateAsync: apiDelete} = deleteMutation;
    const handleConfirmDelete = async () => {
        const deletePromises = table.getSelectedRowModel().rows.map(async obj => {
            await apiDelete({pk: obj.original.id});
        });

        try {
            await Promise.all(deletePromises);
        } catch (error) {
        }


        setDeleteConfirmationOpen(false);
        table.resetRowSelection()
        await queryClient.invalidateQueries({queryKey: ['useApiGetUserFinalBalances', userId]});
    };
    const handleCancelDelete = () => {
        setDeleteConfirmationOpen(false);
    };

    const {data, isLoading} = useApiGetUserFinalBalances(userId);

    useEffect(() => {
        if (data) {
            setRows(data);
        }
    }, [data]);


    const CustomCellRenderer: React.FC<{ row: IFinalBalance, theme: any }> = ({row, theme}) => {
        const {renderIcons} = useCustomCellRenderer(row, theme);
        return renderIcons();
    };


    const columns = useMemo<MRT_ColumnDef<IFinalBalance>[]>(
        () => [
            {
                id: "custom_actions",
                header: "status",
                columnDefType: "display",
                Cell: ({row}) => (<CustomCellRenderer row={row.original} theme={theme}/>)
            },
            {
                accessorKey: 'number',
                header: t('number'),
                filterFn: 'contains',
            },
            {
                accessorKey: 'date',
                header: t('date'),
                Cell: ({row}) => {
                    return formatDateTime(row.original.date);
                }
            },
            {
                header: t('client'),
                filterFn: 'contains',
                Cell: ({row}) => {
                    const {client} = row.original;

                    if (!client) return '';

                    return client.type === 'company' ? client.company_name : client.name;
                }
            },
        ],
        []
    );


    const table = useMaterialReactTable<IFinalBalance>({
        columns,
        defaultColumn: {
            maxSize: 400,
            minSize: 80,
            size: 80,
        },
        data: rows ? rows : [],
        columnFilterDisplayMode: 'popover',
        globalFilterFn: 'contains',
        enableFullScreenToggle: false,
        enableFilters: true,
        enableDensityToggle: true,
        muiTableHeadCellProps: getMuiTableHeadCellProps(theme.palette.primary.main),
        muiTableBodyCellProps: getMuiTableBodyCellProps(theme),
        muiTablePaperProps: getMuiTablePaperProps,
        enablePagination: rows ? rows?.length > 25 : false,
        initialState: {
            density: 'compact',
            pagination: {pageSize: 25, pageIndex: 0},
        },

        mrtTheme: (theme) => ({
            baseBackgroundColor: theme.palette.background.default,

        }),
        muiTableBodyRowProps: getMuiTableBodyRowProps(theme),
        renderTopToolbarCustomActions: ({table}) => (
            <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                <>
                    <Avatar sx={{
                        backgroundColor: 'black',
                        border: `2px solid ${theme.palette.primary.main}`,
                        marginRight: 1
                    }}>
                        <img src={Images.consuntiviYellow} alt={'final balances'}
                             style={{width: '24px', height: '24px'}}/>
                    </Avatar>
                    <MuiReadOnlyBoxBody label={''} value={'final balances'}/>
                    <MRTMuiButton
                        icon={<Add style={{color: theme.palette.primary.main, fontSize: '2rem'}}/>}
                        onClick={() => {
                            setSelectedRow(undefined);
                            handleCreateEstimate();
                        }} label={t('add final balance')}/>
                    {table.getSelectedRowModel().rows.length > 0 &&
                        <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                            <MRTMuiButton
                                icon={<Delete style={{color: theme.palette.warning.main, fontSize: '2rem'}}/>}
                                onClick={() => {
                                    setDeleteConfirmationOpen(true);
                                }} label={t('delete final balances')}/>
                        </Box>
                    }

                </>
            </Box>
        ),
        enableRowSelection: true,
        positionToolbarAlertBanner: 'none',
        onRowSelectionChange: setRowSelection,
        state: {rowSelection},

        enableRowActions: true,
        renderRowActionMenuItems: ({closeMenu, staticRowIndex, row}) => [
            <MRT_ActionMenuItem
                icon={<Edit color="primary"/>}
                key="edit"
                label={t("edit")}
                onClick={(event) => {
                    setSelectedRow(row.original);
                    setSelectedFinalBalance(row.original);
                    setIsEditFormOpen(true)
                    closeMenu()
                }}
                table={table}
            />,
        ],
    });


    return <>
        {isLoading ? <CircularProgress/> :
            <>
                {selectedFinalBalance ?
                    <>
                        <FinalBalanceForm
                            onClose={() => {
                                setSelectedFinalBalance(undefined);
                                setIsEditFormOpen(false)
                            }
                            }
                        />
                    </>
                    :
                    <>
                        <MaterialReactTable table={table}/>


                        <MuiAlertDialog
                            open={isDeleteConfirmationOpen}
                            title="Delete Confirmation"
                            content="Are you sure you want to delete these final balances?"
                            onClose={handleCancelDelete}
                            onConfirm={handleConfirmDelete}
                        />
                    </>}

            </>}
    </>;

};

export default FinalBalanceTable;

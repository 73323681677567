const BLACK_EDILIZIAPP = "#222323";
const YELLOW_EDILIZIAPP = "#FFDB39";
const GREEN_SOFT_EDILIZIAPP = "#00AE42";
const GREEN_DARKER_EDILIZIAPP = "#008375";
const WHITE_EDILIZIAPP = "#ffffff";
const GRAY_DARK_EDILIZIAPP = "#222323";
const GRAY_SOFT_EDILIZIAPP = "#ACACAC";
const GRAY_EDILIZIAPP = "#707070";
const ORANGE_EDILIZIAPP = "#ED883A";
const RED_EDILIZIAPP = "#EC5239";

const bg_from_gradient = GREEN_SOFT_EDILIZIAPP;
const bg_to_gradient = GREEN_DARKER_EDILIZIAPP;

const bg_from_gradient_dark =  BLACK_EDILIZIAPP;
const bg_to_gradient_dark = "#000000";

const inputPlaceholderColor = "rgba(255, 255, 255, .5)";

const defaultBtnTextColor = WHITE_EDILIZIAPP;
const defaultBtnBgColor = BLACK_EDILIZIAPP;

export default {
    BLACK_EDILIZIAPP,
    YELLOW_EDILIZIAPP,
    GREEN_SOFT_EDILIZIAPP,
    GREEN_DARKER_EDILIZIAPP,
    GRAY_DARK_EDILIZIAPP,
    GRAY_SOFT_EDILIZIAPP,
    GRAY_EDILIZIAPP,
    WHITE_EDILIZIAPP,
    ORANGE_EDILIZIAPP,
    RED_EDILIZIAPP,
    bg_from_gradient,
    bg_to_gradient,
    bg_from_gradient_dark,
    bg_to_gradient_dark,
    inputPlaceholderColor,
    defaultBtnTextColor,
    defaultBtnBgColor
}
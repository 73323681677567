import React, {ChangeEvent, useState} from 'react';
import {Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, Typography} from '@mui/material';
import {useApp} from "../../utils/global-contexts/AppContext";
import useApiPostUserImage from "./api-hooks/useApiPostUserImage";
import {useTheme} from "@mui/material/styles";

const maxFileSize = 2000000;

type ProfileImagePickerProps = {
    onClose?: () => void;
};

const ProfileImagePicker: React.FC<ProfileImagePickerProps> = ({onClose}) => {
    const theme = useTheme();
    const {user, setUserImage, userId} = useApp();
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [uri, setUri] = useState<string | null>();
    const [loading, setLoading] = useState<boolean>(false);
    const [fileSizeError, setFileSizeError] = useState<boolean>(false);
    const [uploadError, setUploadError] = useState<string>('');
    const [open, setOpen] = useState<boolean>(true);


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        onClose && onClose();
    };

    const onPressLoadImage = (event: ChangeEvent<HTMLInputElement>) => {
        setFileSizeError(false);
        const file = event.target.files?.[0];
        if (file) {
            if (file.size > maxFileSize) {
                setFileSizeError(true);
            } else {
                setSelectedFile(file);
                setUri(URL.createObjectURL(file));
            }
        }
    };

    const postMutation = useApiPostUserImage();
    const onPressPostImage = async () => {
        setUploadError('');
        if (selectedFile) {
            setLoading(true);
            const formData = new FormData();
            formData.append('image_url', selectedFile);

            try {
                const response = await postMutation.mutateAsync({id: user?.id, formData: formData});
                if (response.image_url) {
                    await setUserImage(response.image_url);
                    setLoading(false);
                    handleClose(); // Close the modal after successful upload
                } else if (response.error) {
                    setUploadError(response.message);
                }
            } catch (err) {
                console.error("Error uploading image:", err);
                setUploadError("Errore di caricamento dell'immagine");
            }
        } else {
            console.log('errorr')
            setUploadError("Seleziona un'immagine");
        }
    };

    return (
        <Box>
            <Dialog open={open} onClose={handleClose}>
                <DialogContent>
                    <input
                        type="file"
                        accept="image/*"
                        onChange={onPressLoadImage}
                        style={{display: 'none'}}
                        id="fileInput"
                    />
                    <label htmlFor="fileInput">
                        <Box component="div">
                            {uri ? (
                                <img src={uri} alt="Selected Profile" style={{width: '100%', height: 'auto'}}/>
                            ) : (
                                <div style={styles.container}>
                                    <Typography variant="h4" style={styles.typography}>
                                        Scegli un'immagine
                                    </Typography>
                                </div>

                            )}
                        </Box>
                    </label>

                    {fileSizeError && (
                        <Typography variant="body2">
                            Errore, immagine troppo grande. Scegli un'immagine con dimensione minore di{' '}
                            {parseFloat((maxFileSize / 1000000).toFixed(0))}MB
                        </Typography>
                    )}
                    {uploadError !== '' && (
                        <Typography variant="body2">
                            {uploadError}
                        </Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    {selectedFile && (
                        <>
                            {loading ? (
                                <CircularProgress size={24}/>
                            ) : (
                                <Button variant="contained" color="primary" onClick={onPressPostImage}>
                                    Carica
                                </Button>
                            )}
                        </>
                    )}
                    <Button variant="contained" color="secondary" onClick={handleClose}>
                        CHIUDI
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid',
        borderRadius: 20,
        padding: '20px',
    },
    typography: {
        cursor: 'pointer',
        textAlign: 'center' as const,
        fontSize: '1.5rem',
    },
};


export default ProfileImagePicker;

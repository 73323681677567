import React, {useEffect, useState} from "react";
import useApiGetUser from "./api-hooks/useApiGetUser";
import {useApp} from "../../utils/global-contexts/AppContext";
import MuiInputTextField from "../../ui/MuiInputData/MuiInputTextField";
import {Box, Grid, Typography} from "@mui/material";
import EdiliziaAppButton from "../../ui/Buttons/EdiliziaAppButton";
import {FaSave} from "react-icons/fa";
import useApiUpdateUser from "./api-hooks/useApiUpdateUser";
import {useTheme} from "@mui/material/styles";
import {FaImagePortrait} from "react-icons/fa6";
import ProfileImagePicker from "./ProfileImagePicker";
import colors from "../../utils/themes/colors";

const ProfileScreen = () => {
    const theme = useTheme();
    const {userId, setUser, user, setUserImage, userImage} = useApp();

    const [name, setName] = useState(user?.name || "");
    const [companyName, setCompanyName] = useState(user?.company_name || "");
    const [phone, setPhone] = useState(user?.phone || "");
    const [mobile, setMobile] = useState(user?.mobile || "");
    const [address, setAddress] = useState(user?.address || "");
    const [city, setCity] = useState(user?.city || "");
    const [zip, setZip] = useState(user?.zip || "");
    const [email, setEmail] = useState(user?.email || "");
    const [vat, setVat] = useState(user?.vat || "");
    const [fiscalCode, setFiscalCode] = useState(user?.fiscal_code || "");
    const [pec, setPec] = useState(user?.pec || "");
    const [sdi, setSdi] = useState(user?.sdi || "");

    const [imagePickerOpen, setImagePickerOpen] = useState(false);

    const {data} = useApiGetUser(userId);

    useEffect(() => {
        if (data) {
            setUser(data);
            setName(data.name || "");
            setCompanyName(data.company_name || "");
            setPhone(data.phone || "");
            setMobile(data.mobile || "");
            setAddress(data.address || "");
            setCity(data.city || "");
            setZip(data.zip || "");
            setEmail(data.email || "");
            setVat(data.vat || "");
            setFiscalCode(data.fiscal_code || "");
            setPec(data.pec || "");
            setSdi(data.sdi || "");
            setUserImage(data.image_url)
        }
    }, [data]);

    const updateMutation = useApiUpdateUser();
    const handleSubmit = async () => {
        try {
            const payload = {
                email: email || '',
                company_name: companyName || '',
                phone: phone || '',
                mobile: mobile || '',
                address: address || '',
                city: city || '',
                zip: zip || '',
                vat: vat || '',
                fiscal_code: fiscalCode || '',
                pec: pec || '',
                sdi: sdi || '',
            };

            await updateMutation.mutateAsync({id: userId, data: payload});
        } catch (error) {
        }
    }

    return (
        <Box>
            <Box display="flex" flexDirection="column" alignItems="center" my={2}>
                {userImage ? (
                    <img
                        src={`${userImage}?${new Date().getTime()}`}
                        alt="User Profile"
                        style={styles.image}
                    />
                ) : (
                    <div style={styles.imagePlaceholder}>
                        <Typography style={styles.txtYellowBold}>Carica il tuo logo</Typography>
                    </div>
                )}
                <Box mt={2}>
                    <EdiliziaAppButton label={'UPLOAD IMAGE'} icon={<FaImagePortrait/>}
                                       onClick={() => setImagePickerOpen(true)}/>
                </Box>
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <MuiInputTextField
                        onChange={(e) => setName(e.target.value)}
                        label='Name'
                        type="text"
                        value={name}
                    />
                </Grid>
                <Grid item xs={4}>
                    <MuiInputTextField
                        onChange={(e) => setCompanyName(e.target.value)}
                        label='Company Name'
                        type="text"
                        value={companyName}
                    />
                </Grid>
                <Grid item xs={4}>
                    <MuiInputTextField
                        onChange={(e) => setPhone(e.target.value)}
                        label='Phone'
                        type="text"
                        value={phone}
                    />
                </Grid>
                <Grid item xs={4}>
                    <MuiInputTextField
                        onChange={(e) => setMobile(e.target.value)}
                        label='Mobile'
                        type="text"
                        value={mobile}
                    />
                </Grid>
                <Grid item xs={4}>
                    <MuiInputTextField
                        onChange={(e) => setAddress(e.target.value)}
                        label='Address'
                        type="text"
                        value={address}
                    />
                </Grid>
                <Grid item xs={4}>
                    <MuiInputTextField
                        onChange={(e) => setCity(e.target.value)}
                        label='City'
                        type="text"
                        value={city}
                    />
                </Grid>
                <Grid item xs={4}>
                    <MuiInputTextField
                        onChange={(e) => setZip(e.target.value)}
                        label='ZIP'
                        type="text"
                        value={zip}
                    />
                </Grid>
                <Grid item xs={4}>
                    <MuiInputTextField
                        onChange={(e) => setEmail(e.target.value)}
                        label='Email'
                        type="email"
                        value={email}
                    />
                </Grid>
                <Grid item xs={4}>
                    <MuiInputTextField
                        onChange={(e) => setVat(e.target.value)}
                        label='VAT'
                        type="text"
                        value={vat}
                    />
                </Grid>
                <Grid item xs={4}>
                    <MuiInputTextField
                        onChange={(e) => setFiscalCode(e.target.value)}
                        label='Fiscal Code'
                        type="text"
                        value={fiscalCode}
                    />
                </Grid>
                <Grid item xs={4}>
                    <MuiInputTextField
                        onChange={(e) => setPec(e.target.value)}
                        label='PEC'
                        type="text"
                        value={pec}
                    />
                </Grid>
                <Grid item xs={4}>
                    <MuiInputTextField
                        onChange={(e) => setSdi(e.target.value)}
                        label='SDI'
                        type="text"
                        value={sdi}
                    />
                </Grid>
            </Grid>
            <Box display="flex" justifyContent="center" mt={2}>
                <EdiliziaAppButton label={'save'} icon={<FaSave/>} onClick={handleSubmit}/>
            </Box>

            {imagePickerOpen &&
                <ProfileImagePicker onClose={() => setImagePickerOpen(false)}/>
            }
        </Box>

    );
};

const styles = {
    image: {
        width: 200,
        height: 200,
        borderRadius: '50%',
        border: '1px solid',
        padding: '4px',
        borderColor: colors.YELLOW_EDILIZIAPP
    },
    imagePlaceholder: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 200,
        height: 200,
        backgroundColor: '#cccccc',
        borderRadius: '50%',
    },
    txtYellowBold: {
        color: 'main',
        fontWeight: 'bold',
    },
};

export default ProfileScreen;

import React from 'react';
import {useTheme} from '@mui/material/styles';
import {Box, Button, Tooltip} from '@mui/material';
import {keyframes} from '@mui/system';
import {useTranslation} from "react-i18next";

interface MuiButtonProps {
    onClick: () => void;
    icon: React.ReactNode;
    label: string;
    animated?: boolean;
    small?: boolean;
}

const pulseAnimation = keyframes({
    '0%': {
        transform: 'scale(1)',
    },
    '50%': {
        transform: 'scale(1.1)',
    },
    '100%': {
        transform: 'scale(1)',
    },
});

const MRTMuiButton: React.FC<MuiButtonProps> = ({onClick, icon, label, animated, small}) => {
    const theme = useTheme();
    const {t} = useTranslation()


    const smallStyles = small
        ? {
            padding: '0px 0px',
            minWidth: 'auto',
            fontSize: '0.75rem',
            '& .MuiBox-root': {
                fontSize: '0.75rem',
            },
            '& .MuiSvgIcon-root': {
                fontSize: '1rem',
            },
        }
        : {};

    return (
        <Tooltip
            placement="top"
            disableFocusListener
            title={t(label)}
            sx={{fontWeight: 'bold'}}
        >
            <Button
                sx={{
                    backgroundColor: 'transparent',
                    '&:hover': {
                        backgroundColor: 'transparent',
                    },
                    ...(animated ? {animation: `${pulseAnimation} 1s infinite`} : {}),
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    ...smallStyles,
                }}
                aria-label="filter"
                onClick={onClick}
            >
                <Box
                    sx={{
                        backgroundColor: 'transparent',
                        '&:hover': {
                            backgroundColor: 'transparent',
                            color: theme.palette.primary.dark,
                        },
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    {icon}
                </Box>
            </Button>
        </Tooltip>
    );
};

export default MRTMuiButton;

import React, {Fragment, useEffect} from 'react';
import {Navigate, useLocation} from "react-router-dom";
import {useAuth} from "../utils/global-contexts/AuthContext";
import useGetWhoAmI from "../utils/custom-hooks/useGetWhoAmI";
import {useApp} from "../utils/global-contexts/AppContext";
import {useQueryClient} from "@tanstack/react-query";

type Props = {
    children: React.ReactNode
}

const ProtectedRoute = ({children}: Props) => {
    const {isAuthenticated, setIsAuthenticated} = useAuth();
    const {selectedEstimate, setSelectedEstimate} = useApp()
    const {pathname} = useLocation()
    const queryClient = useQueryClient();

    const {
        data,
        error
    } = useGetWhoAmI()


    useEffect(() => {
        queryClient.invalidateQueries({queryKey: ['useApiGetEstimateUserfields', selectedEstimate?.id]});
    }, [pathname, selectedEstimate?.id]);


    return (
        <Fragment>
            {
                isAuthenticated
                    ? <Fragment>
                        {children}
                    </Fragment>
                    : <Navigate to="/login" replace/>
            }


        </Fragment>
    )
};

export default ProtectedRoute;

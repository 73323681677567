import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {AxiosError} from "axios";
import useApi from "../../../utils/custom-hooks/api-hooks/useApi";
import {IEstimate} from "../../../utils/interfaces/Estimate/IEstimate";


const useApiGetUserEstimates = (user_id: number | undefined): UseQueryResult<IEstimate[], AxiosError> => {
    const {get} = useApi()

    async function doGet(user_id: number | undefined): Promise<IEstimate[]> {
        try {
            const response = await get(`/users/${user_id}/estimates`);
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetUserEstimates", user_id],
        queryFn: () => doGet(user_id),
        enabled: !!user_id
    })
};

export default useApiGetUserEstimates

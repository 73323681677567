import React, {useEffect, useState} from "react";
import {Box, Checkbox, FormControlLabel, Grid} from "@mui/material";
import {IEstimate} from "../../utils/interfaces/Estimate/IEstimate";
import useApiUpdateEstimate from "./api-hooks/useApiUpdateEstimate";
import useApiCreateEstimate from "./api-hooks/useApiCreateEstimate";
import useApiGetEstimate from "./api-hooks/useApiGetEstimate";
import UndoButton from "../../ui/Buttons/UndoButton";
import {FaSave} from "react-icons/fa";
import EdiliziaAppButton from "../../ui/Buttons/EdiliziaAppButton";
import MuiInputTextField from "../../ui/MuiInputData/MuiInputTextField";
import {formatDateTimeForInput} from "../../logics/helpers";
import MuiBorderLabel from "../../ui/MuiBorderLabel";
import ClientSelect from "../Client/ClientSelect";
import {IClient} from "../../utils/interfaces/Client/IClient";
import {useTranslation} from "react-i18next";
import {ITaxesType} from "../../utils/taxes/ITaxesType";
import taxes_types from "../../utils/taxes/taxes_types";
import TaxesypeSelect from "../../utils/taxes/TaxesypeSelect";
import UserFieldTable from "../UserField/UserFieldTable";
import {useApp} from "../../utils/global-contexts/AppContext";


interface Props {
    onClose: () => void;
    onSelect?: (obj: IEstimate | undefined) => void,
}

const EstimateForm: React.FC<Props> = ({onClose, onSelect}) => {
    const {selectedEstimate} = useApp()
    const {t} = useTranslation()
    const [date, setDate] = useState<string>()
    const [number, setNumber] = useState<string>()
    const [client, setClient] = useState<IClient>()
    const [name, setName] = useState<string>()
    const [note, setNote] = useState<string>()
    const [deadline, setDeadline] = useState<string>()
    const [showTaxes, setShowTaxes] = useState<boolean>(false)
    const [taxes, setTaxes] = useState<number>()
    const [taxesObj, setTaxesObj] = useState<ITaxesType>()


    const createMutation = useApiCreateEstimate();
    const updateMutation = useApiUpdateEstimate();

    const {
        data: detailedObj,
    } = useApiGetEstimate(selectedEstimate?.id);


    useEffect(() => {
        if (detailedObj) {
            setDate(detailedObj.date)
            setNumber(detailedObj.number)
            setClient(detailedObj.client)
            setName(detailedObj.name)

            setNote(detailedObj.notes)
            setDeadline(detailedObj.deadline)
            setShowTaxes(detailedObj.show_taxes)
            setTaxes(detailedObj.taxes)

        }
    }, [detailedObj]);


    const handleSubmit = async () => {
        try {
            const payload = {
                date: date,
                number: number,
                client: client?.id,
                name: name,
                notes: note || '',
                deadline: deadline,
                show_taxes: showTaxes ? 1 : 0,
                taxes: taxes
            };

            let newData;

            if (detailedObj !== null && detailedObj !== undefined) {
                newData = await updateMutation.mutateAsync({id: detailedObj.id, data: payload});
            } else {
                newData = await createMutation.mutateAsync({data: payload});
            }
            onClose();
            onSelect && onSelect(newData);

        } catch (error) {
        }
    }

    const [allRequirementAreSatisfied, setAllRequirementAreSatisfied] = useState(false);
    useEffect(() => {
        setAllRequirementAreSatisfied(false)
        setAllRequirementAreSatisfied(true);

    }, []);

    const handleChangeClient = (obj: IClient | null) => {
        if (obj) {
            setClient(obj);
        } else {
            setClient(undefined)
        }
    };

    useEffect(() => {
        setTaxesObj(taxes_types.find((item) => item.id === taxes));
    }, [taxes]);


    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                marginTop: '24px',
                marginX: '24px'
            }}
        >
            <MuiBorderLabel label={'estimate info'}>
                <Grid container spacing={1}>
                    <Grid item xs={3}>
                        <ClientSelect onChange={handleChangeClient} className={''} obj={client}/>
                    </Grid>
                    <Grid item xs={3}>
                        <MuiInputTextField
                            sx={{marginTop: '24px'}}
                            onChange={(e) => setDate(e.target.value)}
                            label='date'
                            type="date"
                            value={formatDateTimeForInput(date) || ''}
                            required={true}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <MuiInputTextField
                            sx={{marginTop: '24px'}}
                            onChange={(e) => setNumber(e.target.value)}
                            label='number'
                            type="text"
                            value={number || ''}
                            required={true}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <MuiInputTextField
                            sx={{marginTop: '24px'}}
                            onChange={(e) => setName(e.target.value)}
                            label='object'
                            type="text"
                            value={name || ''}
                            required={false}
                        />
                    </Grid>
                </Grid>
            </MuiBorderLabel>

            <MuiBorderLabel label={'processings'}>
                <UserFieldTable/>
            </MuiBorderLabel>


            <MuiBorderLabel label={'estimate final data'}>
                <Grid container spacing={1}>
                    <Grid item xs={4}>
                        <MuiInputTextField
                            onChange={(e) => setNote(e.target.value)}
                            label='notes'
                            type="text"
                            value={note}
                            rows={10}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <MuiInputTextField
                            onChange={(e) => setDeadline(e.target.value)}
                            label='deadline'
                            type="date"
                            value={formatDateTimeForInput(deadline) || ''}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={showTaxes}
                                    onChange={(event) => setShowTaxes(event.target.checked)}
                                />
                            }
                            label={t("show taxes")}
                        />
                        {showTaxes &&

                            <TaxesypeSelect obj={taxesObj} onChange={(obj) => setTaxes(obj?.id)}
                                            className={''}></TaxesypeSelect>
                        }
                    </Grid>


                </Grid>
            </MuiBorderLabel>


            <Box style={{display: 'flex', justifyContent: 'center', gap: '4px', marginTop: '8px'}}>
                <UndoButton onClose={onClose}/>
                {allRequirementAreSatisfied &&
                    <EdiliziaAppButton label={'save'} icon={<FaSave/>} onClick={handleSubmit}/>
                }
            </Box>

        </Box>
    );
};

export default EstimateForm;

import React from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import HomeScreen from "../components/Home/HomeScreen";
import LoginScreen from "../components/Login/LoginScreen";
import ProtectedRoutesLayout from "../ui/Layout/ProtectedRoutesLayout";
import ProfileScreen from "../components/Profile/ProfileScreen";
import EstimateScreen from "../components/Estimate/EstimateScreen";
import ClientScreen from "../components/Client/ClientScreen";
import CatalogueScreen from "../components/Catalogue/CatalogueScreen";
import FinalBalanceScreen from "../components/FinalBalance/FinalBalanceScreen";

const Navigation = () => {


    return (
        <Routes>
            <Route path="/login" element={<LoginScreen/>}/>
            <Route path="/" element={<ProtectedRoutesLayout/>}>
                <Route path="/*" element={<Navigate to={"/home"}/>}/>
                <Route path="/home" element={<HomeScreen/>}/>
                <Route path="/profile" element={<ProfileScreen/>}/>
                <Route path="/estimate" element={<EstimateScreen/>}/>
                <Route path="/client" element={<ClientScreen/>}/>
                <Route path="/catalogue" element={<CatalogueScreen/>}/>
                <Route path="/final_balance" element={<FinalBalanceScreen/>}/>
            </Route>
        </Routes>

    );
};

export default Navigation;

import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Box, TextField} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {IFieldType} from "./IFieldType";
import field_types from "./field_types";
import MuiAutocomplete from "../../ui/MuiAutocomplete";


interface Props {
    obj: IFieldType | undefined,
    onChange: (obj: IFieldType | null) => void,
    className: string,
    label?: string,
    required?: boolean
}

const FieldTypeSelect = ({obj: initialObj, onChange, label, required}: Props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const [objs, setObjs] = useState<IFieldType[]>([]);
    const [obj, setObj] = useState<IFieldType | null | undefined>(initialObj);

    useEffect(() => {
        setObj(initialObj);
    }, [initialObj]);


    useEffect(() => {
        if (field_types) {
            const sortedFieldTypes = [...field_types].sort((a, b) => a.id.localeCompare(b.id));
            setObjs(sortedFieldTypes);
        }
    }, [field_types]);


    const handleOnChange = (event: React.SyntheticEvent, newValue: IFieldType | null) => {
        onChange(newValue);
    };


    return (
        <>
            <Box sx={{alignItems: 'center', width: '100%'}}>
                <MuiAutocomplete
                    id="field_type-select"
                    options={objs}
                    getOptionLabel={(option) => option.id}
                    value={obj ? obj : null}
                    onChange={handleOnChange}
                    isOptionEqualToValue={(option, value) => option.id === value?.id && option.text === value?.text}
                    renderInput={(params) => (
                        <TextField
                            sx={{textTransform: 'capitalize'}}
                            {...params}
                            label={label || t('select field type')}
                            required={required}
                        />
                    )}
                ></MuiAutocomplete>
            </Box>
        </>
    );
}

export default FieldTypeSelect;

import {useMutation, useQueryClient} from "@tanstack/react-query";
import useApi, {CustomAxiosError} from "../../../utils/custom-hooks/api-hooks/useApi";

interface ILoginData {
    email: string;
    password: string;
}

interface ILoginResponse {
    missing_days: number,
    status: string,
    user: number,
    token: string,
}


const useDoPostLogin = () => {
    const queryClient = useQueryClient();
    const {postEncoded} = useApi()

    async function doPostLogin(data: ILoginData): Promise<ILoginResponse> {
        try {
            const response = await postEncoded('/login/users', data);
            return response.data;
        } catch (error) {
            throw error as CustomAxiosError;
        }
    }

    return useMutation({
        mutationFn: doPostLogin,
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ["whoAmI"]})
        }
    });
};

export default useDoPostLogin;

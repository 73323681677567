import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {AxiosError} from "axios";
import useApi from "../../../utils/custom-hooks/api-hooks/useApi";
import {IEstimate} from "../../../utils/interfaces/Estimate/IEstimate";
import {useApp} from "../../../utils/global-contexts/AppContext";


const useApiCreateEstimate = (): UseMutationResult<IEstimate, AxiosError, {
    data: any
}, unknown> => {
    const queryClient = useQueryClient();
    const {userId} = useApp()

    const {postEncoded} = useApi()

    async function doMutation(data: any): Promise<IEstimate> {
        try {
            const response = await postEncoded(`/estimates/`, data);
            return response.data.data as IEstimate;
        } catch (error) {
            throw error;
        }
    }

    return useMutation<IEstimate, AxiosError, {
        data: any
    }, unknown>({
        mutationFn: async ({data}) => {
            return await doMutation(data);
        },
        onSuccess: async (data) => {
            await queryClient.invalidateQueries({queryKey: ['useApiGetUserEstimates', userId]});
        },
    });
};


export default useApiCreateEstimate;

import React, {useState} from "react";
import {Box, Button, ButtonGroup} from "@mui/material";
import {useTranslation} from "react-i18next";
import CategoryTable from "./Category/CategoryTable";
import ProcessingTable from "./Processing/ProcessingTable";
import {useTheme} from "@mui/material/styles";
import {useApp} from "../../utils/global-contexts/AppContext";

const CatalogueScreen = () => {
    const theme = useTheme();
    const {t} = useTranslation();
    const [isCategorized, setIsCategorized] = useState(true);
    const {setSelectedCategory} = useApp()


    return (
        <>
            <Box style={{display: "flex", justifyContent: "center", gap: "4px", marginTop: "12px"}}>
                <ButtonGroup variant="contained" aria-label="Basic button group">
                    <Button
                        onClick={() => setIsCategorized(true)}
                        style={{backgroundColor: isCategorized ? theme.palette.primary.dark : undefined}}
                    >
                        {t("categorized")}
                    </Button>
                    <Button
                        onClick={() => {
                            setIsCategorized(false);
                            setSelectedCategory(undefined)
                        }}
                        style={{backgroundColor: !isCategorized ? theme.palette.primary.dark : undefined}}
                    >
                        {t("uncategorized")}
                    </Button>
                </ButtonGroup>
            </Box>
            {isCategorized && <CategoryTable/>}
            {!isCategorized && <ProcessingTable/>}
        </>
    );
};

export default CatalogueScreen;

import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {AxiosError} from "axios";
import useApi from "../../../utils/custom-hooks/api-hooks/useApi";
import {IClient} from "../../../utils/interfaces/Client/IClient";


const useApiGetUserClient = (user_id: number | undefined, pk: number | undefined): UseQueryResult<IClient, AxiosError> => {
    const {get} = useApi()

    async function doGet(user_id: number | undefined, pk: number | undefined): Promise<IClient> {
        try {
            const response = await get(`/users/${user_id}/clients/${pk}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetUserClients", user_id, pk],
        queryFn: () => doGet(user_id, pk),
        enabled: !!user_id && !!pk
    })
};

export default useApiGetUserClient

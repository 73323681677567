import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {AxiosError} from "axios";
import useApi from "../../../utils/custom-hooks/api-hooks/useApi";
import {useApp} from "../../../utils/global-contexts/AppContext";

type PostUserImageParams = {
    id: number | undefined;
    formData: FormData;
};

interface ICustomResponse {
    message: string,
    image_url: string,
    error: string,
}

const useApiPostUserImage = (): UseMutationResult<ICustomResponse, AxiosError, PostUserImageParams, unknown> => {
    const {postFormData} = useApi();
    const {userId} = useApp();
    const queryClient = useQueryClient();

    async function doMutation(params: PostUserImageParams): Promise<ICustomResponse> {
        const {id, formData} = params;
        try {
            const response = await postFormData(`/users/${id}/uploadimage`, formData);
            return response.data as ICustomResponse;
        } catch (error) {
            throw error;
        }
    }

    return useMutation<ICustomResponse, AxiosError, PostUserImageParams, unknown>({
        mutationFn: (params: PostUserImageParams) => doMutation(params),
        onSuccess: async (data, variables) => {
            await queryClient.invalidateQueries({queryKey: ['useApiGetUser', userId]});
        },
    });
};

export default useApiPostUserImage;

import React, {useState} from "react";
import {Grid, Typography} from "@mui/material";
import {GrStatusGoodSmall} from 'react-icons/gr';
import {useTranslation} from "react-i18next";
import {FilterList} from "@mui/icons-material";
import {useEstimateStatus} from "./useEstimateStatus";
import colors from "../../utils/themes/colors";

interface Props {
    onStatusChange: (status: number | null) => void;
    label?: string
    allowDeselect?: boolean;
}

const EstimateStatusBar: React.FC<Props> = ({onStatusChange, label, allowDeselect = true}) => {
    const [selected, setSelected] = useState<number | null>(null);
    const {t} = useTranslation()

    const handleClick = (id: number) => {
        if (selected === id) {
            if (allowDeselect) {
                setSelected(null);
                onStatusChange(null);
            }
        } else {
            setSelected(id);
            onStatusChange(id);
        }
    };

    const data = useEstimateStatus();

    return (
        <Grid container justifyContent="space-around"
              style={{
                  border: `1px solid ${colors.GRAY_SOFT_EDILIZIAPP}`,
                  borderRadius: '4px',
                  padding: '10px',
                  position: 'relative'
              }}
        >
            <Grid item xs={12}
                  style={{
                      display: 'flex',
                      alignItems: 'center',
                      top: -16,
                      left: 10,
                      position: 'absolute',
                      backgroundColor: 'white',
                  }}>
                <FilterList color="action"/>
                <Typography
                    style={{fontSize: '10px', textTransform: 'uppercase', paddingInline: '10px'}}>
                    {label ? t(label) : t('status filter')}
                </Typography>
            </Grid>
            {data.map((item, idx) => (
                <Grid
                    item
                    key={idx}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                        backgroundColor: selected === item.status_id ? '#ddd' : ''
                    }}
                    onClick={() => handleClick(item.status_id)}
                >
                    <GrStatusGoodSmall color={item.color}/>
                    <Typography style={{marginLeft: '10px', whiteSpace: 'nowrap'}}>{item.statusText}</Typography>
                </Grid>
            ))}
        </Grid>
    );
};

export default EstimateStatusBar;

import {keyframes} from '@mui/system';
import {Theme} from "@mui/material";


export const fadeIn = keyframes`
    0% {
        opacity: 0;
        transform: translateX(0px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
`;


export const getMuiTablePaperProps = () => ({
    elevation: 0,
    sx: {
        marginTop: '8px',
        // cursor: 'pointer',
    },
});


export const getMuiTableHeadCellProps = (bg_color: string) => {
    return {
        sx: {
            backgroundColor: bg_color,
            fontWeight: 'bold',
            color: 'black',
            // fontFamily: 'Arial Rounded MT Regular',
            textTransform: 'uppercase',
            '& .MuiSvgIcon-root': {
                fill: 'black',
            },
            '&:hover .MuiSvgIcon-root': {
                fill: 'black',
            },
        },
    };
};


export const getMuiToolbarAlertBannerProps = (bg_color: string) => {
    return {
        sx: {
            backgroundColor: bg_color,
            textTransform: 'uppercase',
            ".MuiButton-root": {
                color: "black",
            }
        },
    };
};


export const getMuiTableBodyRowProps = (theme: Theme) => ({
    sx: {
        position: 'relative',
        color: theme.palette.text.primary,
        // fontFamily: 'Arial Rounded MT Regular',
        animation: `${fadeIn} 1s ease`,
        "&:hover": {
            backgroundColor: `${theme.palette.background.default} !important`
        },
        borderBottom: `1px solid ${theme.palette.primary.light}`,
        height: '48px'
    },
});


export const getMuiTableBodyCellProps = (theme: Theme, fontSize?: string) => ({
    sx: {
        position: 'relative',
        color: theme.palette.text.primary,
        // fontFamily: 'Arial Rounded MT Regular',
        borderBottom: `1px solid ${theme.palette.primary.light}`,
        ...(fontSize && {fontSize}),
    },
});


export const getMuiTableFooterCellProps = (theme: Theme, fontSize?: string) => ({
    sx: {
        position: 'relative',
        color: theme.palette.text.primary,
        // fontFamily: 'Arial Rounded MT Regular',
        borderBottom: `1px solid ${theme.palette.primary.light}`,
        ...(fontSize && {fontSize}),
    },
});




import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {AxiosError} from "axios";
import {IUserField} from "../../../utils/interfaces/Estimate/IUserField";
import useApi from "../../../utils/custom-hooks/api-hooks/useApi";


const useApiCreateEstimateUserField = (estimate_id: number | undefined): UseMutationResult<IUserField, AxiosError, {
    data: any
}, unknown> => {
    const queryClient = useQueryClient();

    const {postEncoded} = useApi()

    async function doMutation(data: any): Promise<IUserField> {
        try {
            const response = await postEncoded(`/estimates/${estimate_id}/usersfields`, data);
            return response.data.data as IUserField;
        } catch (error) {
            throw error;
        }
    }

    return useMutation<IUserField, AxiosError, {
        data: any
    }, unknown>({
        mutationFn: async ({data}) => {
            return await doMutation(data);
        },
        onSuccess: async (data) => {
            await queryClient.invalidateQueries({queryKey: ['useApiGetEstimateUserfields', estimate_id]});
        },
    });
};


export default useApiCreateEstimateUserField;

import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {AxiosError} from "axios";
import useApi from "../../../utils/custom-hooks/api-hooks/useApi";
import {useApp} from "../../../utils/global-contexts/AppContext";
import {IFinalBalance} from "../../../utils/interfaces/FinalBalance/IFinalBalance";


const useApiCreateFinalBalance = (): UseMutationResult<IFinalBalance, AxiosError, {
    data: any
}, unknown> => {
    const queryClient = useQueryClient();
    const {userId} = useApp()

    const {postEncoded} = useApi()

    async function doMutation(data: any): Promise<IFinalBalance> {
        try {
            const response = await postEncoded(`/finalbalances/`, data);
            return response.data.data as IFinalBalance;
        } catch (error) {
            throw error;
        }
    }

    return useMutation<IFinalBalance, AxiosError, {
        data: any
    }, unknown>({
        mutationFn: async ({data}) => {
            return await doMutation(data);
        },
        onSuccess: async (data) => {
            await queryClient.invalidateQueries({queryKey: ['useApiGetUserFinalBalances', userId]});
        },
    });
};


export default useApiCreateFinalBalance;

import React from 'react';
import {Box, Typography} from '@mui/material';
import colors from "../utils/themes/colors";
import {useTranslation} from "react-i18next";

interface MuiBorderLabelProps {
    label: string;
    children: React.ReactNode;
    icon?: React.ReactNode;
}

const MuiBorderLabel: React.FC<MuiBorderLabelProps> = ({label, children, icon}) => {
    const {t} = useTranslation()

    return (
        <Box
            sx={{
                border: `1px solid ${colors.GRAY_SOFT_EDILIZIAPP}`,
                borderRadius: '4px',
                padding: '8px',
                position: 'relative',
                // display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-around'
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    position: 'absolute',
                    top: -12,
                    left: 10,
                    backgroundColor: 'white',
                }}
            >
                {icon ? icon : null}
                <Typography
                    sx={{fontSize: '10px', textTransform: 'uppercase', pl: '10px', pr: '10px'}}>
                    {t(label)}
                </Typography>
            </Box>
            {children}
        </Box>
    );
};

export default MuiBorderLabel;

import {useTranslation} from "react-i18next";
import colors from "../../utils/themes/colors";

export const useEstimateStatus = () => {
    const {t} = useTranslation();

    return [
        {
            status_id: 0,
            color: colors.GRAY_SOFT_EDILIZIAPP,
            statusText: t('draft'),
        },
        {
            status_id: 1,
            color: colors.ORANGE_EDILIZIAPP,
            statusText: t('waiting'),
        },
        {
            status_id: 2,
            color: colors.GREEN_SOFT_EDILIZIAPP,
            statusText: t('accepted'),
        },
        {
            status_id: 3,
            color: colors.RED_EDILIZIAPP,
            statusText: t('not accepted'),
        },
    ];
}

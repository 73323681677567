import {createTheme, responsiveFontSizes} from '@mui/material/styles';

interface CustomPalette {
    greenDarker: {
        main: string;
    },
    greenSoft: {
        main: string;
    },
}

declare module '@mui/material/styles' {
    interface Palette extends CustomPalette {
    }

    interface PaletteOptions extends CustomPalette {
    }
}

const appTheme = createTheme({
    palette: {
        primary: {
            main: '#FFDB39',
            light: '#f7eab0',
        },
        secondary: {
            main: '#EC5239',
        },
        warning: {
            main: '#e61a3b',
        },
        background: {
            paper: '#f8fafc',
        },
        greenDarker: {
            main: '#008375',
        },
        greenSoft: {
            main: '#00AE42',
        },
    },
});


const responsiveTheme = responsiveFontSizes(appTheme);

export default responsiveTheme;

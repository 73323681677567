import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {AxiosError} from "axios";
import useApi from "../../../utils/custom-hooks/api-hooks/useApi";


interface MutationVariables {
    pk?: number;
}

const useApiDeleteEstimateUserfield = (): UseMutationResult<void, AxiosError, MutationVariables, unknown> => {
    const {DELETE} = useApi();
    const queryClient = useQueryClient();

    const doDelete = async ({pk}: MutationVariables): Promise<void> => {
        try {
            await DELETE(`/usersfields/${pk}`);
        } catch (error) {
            throw error;
        }
    };

    return useMutation<void, AxiosError, MutationVariables>(
        {
            mutationFn: doDelete,
            onSuccess: async (data) => {
            },
        },
    );
};

export default useApiDeleteEstimateUserfield;

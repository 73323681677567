import React, {useEffect, useState} from "react";
import {Box, Grid} from "@mui/material";
import {FaSave} from "react-icons/fa";
import {IProcessing} from "../../../utils/interfaces/Catalogue/IProcessing";
import useApiCreateProcessing from "./api-hooks/useApiCreateProcessing";
import useApiUpdateProcessing from "./api-hooks/useApiUpdateProcessing";
import MuiBorderLabel from "../../../ui/MuiBorderLabel";
import MuiInputTextField from "../../../ui/MuiInputData/MuiInputTextField";
import UndoButton from "../../../ui/Buttons/UndoButton";
import EdiliziaAppButton from "../../../ui/Buttons/EdiliziaAppButton";
import FieldTypeSelect from "../../../utils/field_types/FieldTypeSelect";
import {IFieldType} from "../../../utils/field_types/IFieldType";
import field_types from "../../../utils/field_types/field_types";
import {useApp} from "../../../utils/global-contexts/AppContext";


interface Props {
    objToEdit: IProcessing | null | undefined;
    onClose: () => void;
    onSelect?: (obj: IProcessing | undefined) => void,
}

const ProcessingForm: React.FC<Props> = ({objToEdit: initialObj, onClose, onSelect}) => {
    const {selectedCategory} = useApp()
    const [label, setLabel] = useState<string>()
    const [value, setValue] = useState<number>()
    const [type, setType] = useState<string>()
    const [typeObj, setTypeObj] = useState<IFieldType>()
    const [description, setDescription] = useState<string>()

    const createMutation = useApiCreateProcessing();
    const updateMutation = useApiUpdateProcessing();

    useEffect(() => {
        if (initialObj) {
            setLabel(initialObj.label)
            setValue(initialObj.value)
            setType(initialObj.type)
            setDescription(initialObj.description)
        }
    }, [initialObj]);


    const handleSubmit = async () => {
        try {
            const payload = {
                label: label,
                value: value,
                type: type,
                description: description,
                ...(selectedCategory && {category: selectedCategory.id}),
            };

            let newData;

            if (initialObj !== null && initialObj !== undefined) {
                newData = await updateMutation.mutateAsync({id: initialObj.id, data: payload});
            } else {
                newData = await createMutation.mutateAsync({data: payload});
            }
            onClose();
            onSelect && onSelect(newData);

        } catch (error) {
        }
    }

    const [allRequirementAreSatisfied, setAllRequirementAreSatisfied] = useState(false);
    useEffect(() => {
        setAllRequirementAreSatisfied(false)
        setAllRequirementAreSatisfied(true);

    }, []);

    useEffect(() => {
        setTypeObj(field_types.find((item) => item.text === type));
    }, [type]);


    return (
        <Box>
            <MuiBorderLabel label={'processing'}>
                <Grid container spacing={1}>
                    <Grid item xs={3}>
                        <MuiInputTextField
                            onChange={(e) => setLabel(e.target.value)}
                            label='label processing'
                            type="text"
                            value={label || ''}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FieldTypeSelect obj={typeObj} onChange={(obj) => setType(obj?.text)}
                                         className={''}></FieldTypeSelect>
                    </Grid>
                    <Grid item xs={3}>
                        <MuiInputTextField
                            onChange={(e) => setValue(parseFloat(e.target.value))}
                            label='value'
                            type="text"
                            value={value || ''}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <MuiInputTextField
                            onChange={(e) => setDescription(e.target.value)}
                            label='description'
                            type="text"
                            value={description || ''}
                        />
                    </Grid>
                </Grid>
            </MuiBorderLabel>

            <Box style={{display: 'flex', justifyContent: 'center', gap: '4px', marginTop: '8px'}}>
                <UndoButton onClose={onClose}/>
                {allRequirementAreSatisfied &&
                    <EdiliziaAppButton label={'save'} icon={<FaSave/>} onClick={handleSubmit}/>
                }
            </Box>

        </Box>
    );
};

export default ProcessingForm;

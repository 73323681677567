import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {AxiosError} from "axios";
import useApi from "../../../utils/custom-hooks/api-hooks/useApi";
import {ICatalogue} from "../../../utils/interfaces/Catalogue/ICatalogue";


const useApiGetCatalogue = (): UseQueryResult<ICatalogue, AxiosError> => {
    const {get} = useApi()

    async function doGet(): Promise<ICatalogue> {
        try {
            const response = await get(`/catalogue/categories/?processings=1`);
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetCatalogue"],
        queryFn: () => doGet(),
    })
};

export default useApiGetCatalogue

import {useMutation, UseMutationResult} from "@tanstack/react-query";
import {AxiosError} from "axios";
import useApi from "../../../utils/custom-hooks/api-hooks/useApi";


interface MutationVariables {
    pk?: number;
}

const useApiDeleteEstimate = (): UseMutationResult<void, AxiosError, MutationVariables, unknown> => {
    const {DELETE} = useApi();

    const doDelete = async ({pk}: MutationVariables): Promise<void> => {
        try {
            await DELETE(`/estimates/${pk}`);
        } catch (error) {
            throw error;
        }
    };

    return useMutation<void, AxiosError, MutationVariables>(
        {
            mutationFn: doDelete,
        },
    );
};

export default useApiDeleteEstimate;

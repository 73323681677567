import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {AxiosError} from "axios";
import useApi from "../../../utils/custom-hooks/api-hooks/useApi";
import {IFinalBalance} from "../../../utils/interfaces/FinalBalance/IFinalBalance";


const useApiGetFinalBalance = (pk: number | undefined): UseQueryResult<IFinalBalance, AxiosError> => {
    const {get} = useApi()

    async function doGet(pk: number | undefined): Promise<IFinalBalance> {
        try {
            const response = await get(`/finalbalances/${pk}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetFinalBalance", pk],
        queryFn: () => doGet(pk),
        enabled: !!pk
    })
};

export default useApiGetFinalBalance

import {useTheme} from "@mui/material/styles";
import {useQueryClient} from "@tanstack/react-query";
import {useApp} from "../../utils/global-contexts/AppContext";
import React, {useEffect, useMemo, useState} from "react";
import {MaterialReactTable, MRT_ActionMenuItem, MRT_ColumnDef, useMaterialReactTable} from "material-react-table";
import {
    getMuiTableBodyCellProps,
    getMuiTableBodyRowProps,
    getMuiTableHeadCellProps,
    getMuiTablePaperProps
} from "../../ui/MuiReactTable/MuiReactTableStyles";
import {Avatar, Box, CircularProgress, Grid} from "@mui/material";
import MuiReadOnlyBoxBody from "../../ui/MuiDisplayData/MuiReadOnlyBoxBody";
import MRTMuiButton from "../../ui/MuiReactTable/MRTMuiButton";
import Images from "../../assets/images";
import {Add, Delete, Edit} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import MuiModal from "../../ui/MuiModals/MuiModal";
import MuiAlertDialog from "../../ui/MuiAlertDialog";
import {IClient} from "../../utils/interfaces/Client/IClient";
import useApiDeleteClient from "./api-hooks/useApiDeleteClient";
import useApiGetUserClients from "./api-hooks/useApiGetUserClients";
import useCustomCellRenderer from "./useCustomCellRenderer";
import ClientForm from "./ClientForm";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import UndoButton from "../../ui/Buttons/UndoButton";


interface Props {
    alreadySelectedObj?: IClient | undefined,
    onSelect?: (obj: IClient | undefined) => void,
    onClose?: () => void;
}


const ClientTable = ({onSelect, onClose, alreadySelectedObj}: Props) => {
    const theme = useTheme();
    const {t} = useTranslation()
    const {userId} = useApp();
    const queryClient = useQueryClient();

    const [selectedRow, setSelectedRow] = useState<IClient | undefined>()
    const [rows, setRows] = useState<IClient[] | undefined>()

    const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false)

    const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const deleteMutation = useApiDeleteClient();
    const {mutateAsync: apiDelete} = deleteMutation;
    const handleConfirmDelete = async () => {
        try {
            if (onSelect && (alreadySelectedObj?.id === selectedRow?.id)) {
                onSelect(undefined);
            }
            await apiDelete({pk: selectedRow?.id});
        } catch (error) {
        }
        setDeleteConfirmationOpen(false);
    };
    const handleCancelDelete = () => {
        setDeleteConfirmationOpen(false);
    };

    const {data, isLoading} = useApiGetUserClients(userId);

    useEffect(() => {
        if (data) {
            setRows(data);
        }
    }, [data]);


    const CustomCellRenderer: React.FC<{ row: IClient, theme: any }> = ({row, theme}) => {
        const {renderIcons} = useCustomCellRenderer(row, theme);
        return renderIcons();
    };


    const columns = useMemo<MRT_ColumnDef<IClient>[]>(
        () => [
            {
                id: "custom_actions",
                header: t("client type"),
                columnDefType: "display",
                Cell: ({row}) => (<CustomCellRenderer row={row.original} theme={theme}/>)
            },
            {
                accessorKey: 'name',
                header: t('name'),
                filterFn: 'contains',
            },
            {
                accessorKey: 'company_name',
                header: t('company name'),
                filterFn: 'contains',
            },
            {
                accessorKey: 'email',
                header: t('email'),
                filterFn: 'contains',
            },

        ],
        []
    );


    const table = useMaterialReactTable<IClient>({
        columns,
        defaultColumn: {
            maxSize: 400,
            minSize: 80,
            size: 80,
        },
        data: rows ? rows : [],
        columnFilterDisplayMode: 'popover',
        globalFilterFn: 'contains',
        enableFullScreenToggle: false,
        enableFilters: true,
        enableDensityToggle: true,
        muiTableHeadCellProps: getMuiTableHeadCellProps(theme.palette.primary.main),
        muiTableBodyCellProps: getMuiTableBodyCellProps(theme),
        muiTablePaperProps: getMuiTablePaperProps,
        enablePagination: rows ? rows?.length > 25 : false,
        initialState: {
            density: 'compact',
            pagination: {pageSize: 25, pageIndex: 0},
        },

        mrtTheme: (theme) => ({
            baseBackgroundColor: theme.palette.background.default,

        }),
        muiTableBodyRowProps: getMuiTableBodyRowProps(theme),
        renderTopToolbarCustomActions: ({table}) => (
            <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                <>
                    <Avatar sx={{
                        backgroundColor: 'black',
                        border: `2px solid ${theme.palette.primary.main}`,
                        marginRight: 1
                    }}>
                        <img src={Images.peopleWhite} alt={'clients'}
                             style={{width: '24px', height: '24px'}}/>
                    </Avatar>
                    <MuiReadOnlyBoxBody label={''} value={'clients'}/>
                    <MRTMuiButton
                        icon={<Add style={{color: theme.palette.primary.main, fontSize: '2rem'}}/>}
                        onClick={() => {
                            setSelectedRow(undefined);
                            setIsEditFormOpen(true);
                        }} label={t('add client')}/>

                </>
            </Box>
        ),

        enableRowActions: true,
        renderRowActionMenuItems: ({closeMenu, staticRowIndex, row}) => [
            onSelect && onClose && (
                <MRT_ActionMenuItem
                    icon={<AdsClickIcon color="primary"/>}
                    key="select"
                    label={t("select")}
                    onClick={() => {
                        onSelect(row.original);
                        onClose();
                    }}
                    table={table}
                />),
            <MRT_ActionMenuItem
                icon={<Edit color="primary"/>}
                key="edit"
                label={t("edit")}
                onClick={(event) => {
                    setSelectedRow(row.original);
                    setIsEditFormOpen(true)
                    closeMenu()
                }}
                table={table}
            />,
            <MRT_ActionMenuItem
                icon={<Delete color="warning"/>}
                key="delete"
                label={t("delete")}
                onClick={(event) => {
                    setSelectedRow(row.original);
                    setDeleteConfirmationOpen(true)
                    closeMenu()
                }}
                table={table}
            />,
        ],
    });


    return <>
        {isLoading ? <CircularProgress/> :
            <>
                {onClose &&
                    <Grid item xs={12} style={{textAlign: 'center'}}>
                        <Box className={'mt-2 gap-x-2 mb-2'}>
                            <UndoButton onClose={onClose}/>
                        </Box>
                    </Grid>}

                <MaterialReactTable table={table}/>


                <MuiModal
                    top="50%"
                    left="50%"
                    width={'100vH'}
                    open={isEditFormOpen}
                    onClose={() => setIsEditFormOpen(false)}
                    disableBackdropClick={true}
                >
                    <ClientForm
                        objToEdit={selectedRow}
                        onClose={() => setIsEditFormOpen(false)}
                    />
                </MuiModal>

                <MuiAlertDialog
                    open={isDeleteConfirmationOpen}
                    title="Delete Confirmation"
                    content="Are you sure you want to delete this client?"
                    onClose={handleCancelDelete}
                    onConfirm={handleConfirmDelete}
                />


            </>}
    </>;

};

export default ClientTable;

import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {AxiosError} from "axios";
import useApi from "../../../utils/custom-hooks/api-hooks/useApi";
import {useApp} from "../../../utils/global-contexts/AppContext";
import {IFinalBalance} from "../../../utils/interfaces/FinalBalance/IFinalBalance";


const useApiCreateFinalBalanceFromEstimate = (): UseMutationResult<IFinalBalance, AxiosError, {
    estimate_id: number | undefined,
}, unknown> => {
    const queryClient = useQueryClient();
    const {userId} = useApp()

    const {postEncoded} = useApi()

    async function doMutation(estimate_id: number | undefined): Promise<IFinalBalance> {
        try {
            const response = await postEncoded(`/estimates/${estimate_id}/createfinalbalance`);
            return response.data.data as IFinalBalance;
        } catch (error) {
            throw error;
        }
    }

    return useMutation<IFinalBalance, AxiosError, {
        estimate_id: number | undefined
    }, unknown>({
        mutationFn: async ({estimate_id}) => {
            return await doMutation(estimate_id);
        },
        onSuccess: async (data) => {
            await queryClient.invalidateQueries({queryKey: ['useApiGetUserFinalBalances', userId]});
        },
    });
};


export default useApiCreateFinalBalanceFromEstimate;

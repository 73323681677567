import React from 'react';
import {Box} from "@mui/material";
import {IconContext} from "react-icons";
import {useTranslation} from "react-i18next";
import {IFinalBalance} from "../../utils/interfaces/FinalBalance/IFinalBalance";

const useCustomCellRenderer = (row: IFinalBalance, theme: any) => {
    const {t} = useTranslation()

    const getIconsForCustomField = (row: IFinalBalance, theme: any) => {
        // const statusObject = statusData.find(item => item.status_id === row.status);
        // const color = statusObject ? statusObject.color : 'grey';
        // const statusText = statusObject ? statusObject.statusText : 'unknown';

        return (
            <div style={{display: 'flex', alignItems: 'center'}}>
                {/*<GrStatusGoodSmall color={color}/>*/}
                {/*<Typography style={{marginLeft: '10px'}}>{statusText}</Typography>*/}
            </div>
        );
    };


    const renderIcons = () => {
        const icons = getIconsForCustomField(row, theme);
        return (
            <IconContext.Provider value={{size: "1.3rem"}}>
                <Box display="flex" sx={{gap: 0.5}}>
                    <span>{icons}</span>
                </Box>
            </IconContext.Provider>
        );
    };

    return {renderIcons};
};

export default useCustomCellRenderer;

import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {AxiosError} from "axios";
import {useApp} from "../../../../utils/global-contexts/AppContext";
import useApi from "../../../../utils/custom-hooks/api-hooks/useApi";


interface MutationVariables {
    pk?: number;
}

const useApiDeleteProcessing = (): UseMutationResult<void, AxiosError, MutationVariables, unknown> => {
    const {DELETE} = useApi();
    const queryClient = useQueryClient();
    const {selectedCategory} = useApp();

    const doDelete = async ({pk}: MutationVariables): Promise<void> => {
        try {
            await DELETE(`/catalogue/processings/${pk}`);
        } catch (error) {
            throw error;
        }
    };

    return useMutation<void, AxiosError, MutationVariables>(
        {
            mutationFn: doDelete,
            onSuccess: async (data) => {
                await queryClient.invalidateQueries({queryKey: ['useApiGetCatalogue']});
                if (selectedCategory) {
                    await queryClient.invalidateQueries({queryKey: ['useApiGetCategory', selectedCategory.id]});
                }
            },
        },
    );
};

export default useApiDeleteProcessing;

import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {AxiosError} from "axios";
import useApi from "../../../utils/custom-hooks/api-hooks/useApi";
import {IClient} from "../../../utils/interfaces/Client/IClient";


const useApiGetUserClients = (user_id: number | undefined): UseQueryResult<IClient[], AxiosError> => {
    const {get} = useApi()

    async function doGet(user_id: number | undefined): Promise<IClient[]> {
        try {
            const response = await get(`/users/${user_id}/clients`);
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetUserClients", user_id],
        queryFn: () => doGet(user_id),
        enabled: !!user_id
    })
};

export default useApiGetUserClients

import React from 'react';
import {Box, Typography} from "@mui/material";
import {IconContext} from "react-icons";
import {IEstimate} from "../../utils/interfaces/Estimate/IEstimate";
import {GrStatusGoodSmall} from "react-icons/gr";
import {useEstimateStatus} from "./useEstimateStatus";
import {useTranslation} from "react-i18next";

const useCustomCellRenderer = (row: IEstimate, theme: any) => {
    const statusData = useEstimateStatus();
    const {t} = useTranslation()

    const getIconsForCustomField = (row: IEstimate, theme: any) => {
        const statusObject = statusData.find(item => item.status_id === row.status);
        const color = statusObject ? statusObject.color : 'grey';
        const statusText = statusObject ? statusObject.statusText : 'unknown';

        return (
            <div style={{display: 'flex', alignItems: 'center'}}>
                <GrStatusGoodSmall color={color}/>
                <Typography style={{marginLeft: '10px'}}>{statusText}</Typography>
            </div>
        );
    };


    const renderIcons = () => {
        const icons = getIconsForCustomField(row, theme);
        return (
            <IconContext.Provider value={{size: "1.3rem"}}>
                <Box display="flex" sx={{gap: 0.5}}>
                    <span>{icons}</span>
                </Box>
            </IconContext.Provider>
        );
    };

    return {renderIcons};
};

export default useCustomCellRenderer;

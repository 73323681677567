import {AppBar, IconButton, Stack, Toolbar, Typography} from "@mui/material";
import {VscMenu, VscSignOut} from "react-icons/vsc";
import ButtonLogout from "../Buttons/ButtonLogout";
import React from "react";

interface Props {
    toggleNavBar: () => void
    pageTitle: string
}

const TopBar = ({toggleNavBar, pageTitle}: Props) => {

    return (
        <AppBar>
            <Toolbar
                sx={{
                    justifyContent: "space-between",
                    alignItems: "center"
                }}
            >
                <IconButton onClick={toggleNavBar}>
                    <VscMenu size={24} color={"black"}/>
                </IconButton>
                <Stack direction={"row"} alignItems={"center"}>
                    <Typography sx={{
                        color: 'black',
                        textTransform: 'uppercase',
                        fontFamily: 'fantasy'
                    }}>{pageTitle} | </Typography>
                    <ButtonLogout
                        logoutIcon={<VscSignOut/>}
                        disableElevation={true}
                    />
                </Stack>
            </Toolbar>
        </AppBar>
    )
}

export default TopBar;

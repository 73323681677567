import React from 'react';
import {Box} from "@mui/material";
import {IconContext} from "react-icons";
import {IClient} from "../../utils/interfaces/Client/IClient";
import colors from "../../utils/themes/colors";
import {BsPeople} from "react-icons/bs";
import {BiSolidFactory} from "react-icons/bi";


const useCustomCellRenderer = (row: IClient, theme: any) => {

    const getIconsForCustomField = (row: IClient, theme: any) => {
        return (
            <div style={{display: 'flex', alignItems: 'center'}}>
                {row.type === 'company' &&
                    <BiSolidFactory color={colors.GREEN_SOFT_EDILIZIAPP}/>}
                {row.type === 'private' &&
                    <BsPeople color={colors.GREEN_SOFT_EDILIZIAPP}/>}
            </div>
        );
    };


    const renderIcons = () => {
        const icons = getIconsForCustomField(row, theme);
        return (
            <IconContext.Provider value={{size: "1.3rem"}}>
                <Box display="flex" sx={{gap: 0.5}}>
                    <span>{icons}</span>
                </Box>
            </IconContext.Provider>
        );
    };

    return {renderIcons};
};

export default useCustomCellRenderer;

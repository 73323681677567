import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {AxiosError} from "axios";
import {IUserDetailed} from "../../../utils/interfaces/Profile/IUserDetailed";
import useApi from "../../../utils/custom-hooks/api-hooks/useApi";


const useApiUpdateUser = (): UseMutationResult<IUserDetailed, AxiosError, {
    id: number | undefined;
    data: any
}, unknown> => {
    const queryClient = useQueryClient();

    const {put} = useApi()

    async function doMutation(pk: number | undefined,
                              data: any): Promise<IUserDetailed> {
        try {
            const response = await put(`/users/${pk}`, data);
            return response.data.data as IUserDetailed;
        } catch (error) {
            throw error;
        }
    }

    return useMutation<IUserDetailed, AxiosError, {
        id: number | undefined;
        data: any
    }, unknown>({
        mutationFn: async ({id, data}) => {
            return await doMutation(id, data);
        },
        onSuccess: async (data) => {
            await queryClient.invalidateQueries({queryKey: ['useApiGetUser', data.id]});
        },
    });
};


export default useApiUpdateUser;

import React, {ReactNode} from "react";
import {Button, ButtonProps as MuiButtonProps} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import colors from "../../utils/themes/colors";


interface Props extends MuiButtonProps {
    label: string,
    icon: ReactNode,
    onClick: () => void
    custom_bg_color?: string,
    custom_color?: string,
}

const EdiliziaAppButton = ({label, icon, onClick, custom_color, custom_bg_color, ...props}: Props) => {
    const theme = useTheme()
    const {t} = useTranslation()


    return (
        <Button
            variant="outlined"
            endIcon={icon}
            onClick={onClick}
            {...props}
            sx={{
                backgroundColor: custom_bg_color ? custom_bg_color : 'black',
                color: custom_color ? custom_color : theme.palette.primary.main,
                borderColor: 'black',
                '&:hover': {
                    backgroundColor: custom_bg_color ? custom_bg_color : 'black',
                    borderColor: colors.GRAY_SOFT_EDILIZIAPP,
                    color: colors.GRAY_SOFT_EDILIZIAPP
                }
            }}
        >
            {t(label)}
        </Button>

    )
}

export default EdiliziaAppButton;

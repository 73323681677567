import React, {useEffect, useState} from "react";
import {Box, Grid} from "@mui/material";
import {FaSave} from "react-icons/fa";
import MuiBorderLabel from "../../../ui/MuiBorderLabel";
import MuiInputTextField from "../../../ui/MuiInputData/MuiInputTextField";
import UndoButton from "../../../ui/Buttons/UndoButton";
import EdiliziaAppButton from "../../../ui/Buttons/EdiliziaAppButton";
import {ICategory} from "../../../utils/interfaces/Catalogue/ICategory";
import useApiCreateCategory from "./api-hooks/useApiCreateCategory";
import useApiUpdateCategory from "./api-hooks/useApiUpdateCategory";
import {useQueryClient} from "@tanstack/react-query";


interface Props {
    objToEdit: ICategory | null | undefined;
    onClose: () => void;
    onSelect?: (obj: ICategory | undefined) => void,
}

const CategoryForm: React.FC<Props> = ({objToEdit: initialObj, onClose, onSelect}) => {
    const queryClient = useQueryClient();
    const [label, setLabel] = useState<string>()

    const createMutation = useApiCreateCategory();
    const updateMutation = useApiUpdateCategory();

    useEffect(() => {
        if (initialObj) {
            setLabel(initialObj.label)
        }
    }, [initialObj]);


    const handleSubmit = async () => {
        try {
            const payload = {
                label: label,
            };

            let newData;

            if (initialObj !== null && initialObj !== undefined) {
                newData = await updateMutation.mutateAsync({id: initialObj.id, data: payload});
                await queryClient.invalidateQueries({queryKey: ['useApiGetCategory', initialObj.id]});
            } else {
                newData = await createMutation.mutateAsync({data: payload});
            }
            onClose();
            onSelect && onSelect(newData);

        } catch (error) {
            console.log(error)
        }
    }

    const [allRequirementAreSatisfied, setAllRequirementAreSatisfied] = useState(false);
    useEffect(() => {
        setAllRequirementAreSatisfied(false)
        setAllRequirementAreSatisfied(true);
    }, []);


    return (
        <Box>
            <MuiBorderLabel label={'category'}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <MuiInputTextField
                            onChange={(e) => setLabel(e.target.value)}
                            label='label category'
                            type="text"
                            value={label || ''}
                        />
                    </Grid>

                </Grid>
            </MuiBorderLabel>

            <Box style={{display: 'flex', justifyContent: 'center', gap: '4px', marginTop: '8px'}}>
                <UndoButton onClose={onClose}/>
                {allRequirementAreSatisfied &&
                    <EdiliziaAppButton label={'save'} icon={<FaSave/>} onClick={handleSubmit}/>
                }
            </Box>

        </Box>
    );
};

export default CategoryForm;

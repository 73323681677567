import React, {FC} from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material';
import {useTranslation} from "react-i18next";
import {useTheme} from "@mui/material/styles";
import UndoButton from "./Buttons/UndoButton";
import ConfirmButton from "./Buttons/ConfirmButton";

interface AlertDialogProps {
    open: boolean;
    title: string;
    content: string;
    onClose: () => void;
    onConfirm?: () => void;
}

const MuiAlertDialog: FC<AlertDialogProps> = ({open, title, content, onClose, onConfirm}) => {
    const {t} = useTranslation()
    const theme = useTheme();

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle style={{
                textAlign: 'center',
                paddingInline: '16px',
                paddingTop: '16px',
                paddingBottom: '8px',
                textTransform: 'uppercase',
                color: theme.palette.warning.main,
                fontWeight: 600
            }}>
                {t(title)}
            </DialogTitle>
            <DialogContent style={{textAlign: 'center', padding: '16px'}}>
                <Typography variant="body1" style={{
                    color: '#616161' // grey color for content
                }}>
                    {t(content)}
                </Typography>
            </DialogContent>
            <DialogActions style={{justifyContent: 'center', padding: '16px'}}>

                <UndoButton onClose={onClose}></UndoButton>
                {onConfirm &&
                    <ConfirmButton onConfirm={onConfirm}/>}

            </DialogActions>
        </Dialog>
    );
};

export default MuiAlertDialog;

import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {AxiosError} from "axios";
import {IUserField} from "../../../utils/interfaces/Estimate/IUserField";
import useApi from "../../../utils/custom-hooks/api-hooks/useApi";


const useApiUpdateFinalBalanceUserField = (): UseMutationResult<IUserField, AxiosError, {
    id: number | undefined;
    data: any
}, unknown> => {
    const queryClient = useQueryClient();
    const {putEncoded, put} = useApi()

    async function doMutation(
        pk: number | undefined,
        data: any): Promise<IUserField> {
        try {
            const response = await put(`/finalbalancesusersfields/${pk}`, data);
            return response.data.data as IUserField;
        } catch (error) {
            throw error;
        }
    }

    return useMutation<IUserField, AxiosError, {
        id: number | undefined;
        data: any
    }, unknown>({
        mutationFn: async ({id, data}) => {
            return await doMutation(id, data);
        },
        onSuccess: async (data) => {

        },
    });
};


export default useApiUpdateFinalBalanceUserField;

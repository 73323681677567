import {FC} from 'react';
import {useTheme} from '@mui/material/styles';
import UndoIcon from '@mui/icons-material/Undo';
import EdiliziaAppButton from "./EdiliziaAppButton";

interface UndoButtonProps {
    onClose: () => void;
}

const UndoButton: FC<UndoButtonProps> = ({onClose}) => {
    const theme = useTheme();

    return (
        <EdiliziaAppButton label={'undo'}
                           icon={<UndoIcon/>}
                           custom_bg_color={theme.palette.primary.main}
                           custom_color={'black'}
                           onClick={onClose}/>
    );
}

export default UndoButton;

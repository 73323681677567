import React from 'react';
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {ThemeProvider} from "@mui/material";
import responsiveTheme from "../utils/themes/global-theme";
import {ResponseMessagesProvider} from "../utils/global-contexts/ResponseMessageContext";
import {ProvideAuth} from "../utils/global-contexts/AuthContext";
import {ProvideAApp} from "../utils/global-contexts/AppContext";
import {I18nextProvider} from "react-i18next";
import i18n from "../translation/i18n";


type Props = {
    children: React.ReactNode;
}

const queryClient = new QueryClient()
const MainProviders = ({children}: Props) => {
    return (

        <ResponseMessagesProvider>
            <QueryClientProvider client={queryClient}>
                <ProvideAApp>
                    <I18nextProvider i18n={i18n}>
                        <ProvideAuth>
                            <ThemeProvider theme={responsiveTheme}>
                                {children}
                            </ThemeProvider>
                        </ProvideAuth>
                    </I18nextProvider>
                </ProvideAApp>
            </QueryClientProvider>
        </ResponseMessagesProvider>


    );
};

export default MainProviders;

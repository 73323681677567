export const formatDateTime = (d: any) => {
    if (d) {
        d = d.slice(0, 10)
        const [year, month, day] = d.split("-");
        return `${day}-${month}-${year}`;
    } else {
        return d;
    }
};

export const formatDateTimeForInput = (d: any) => {
    if (d) {
        return d.split('T')[0];
    } else {
        return '';
    }
};

import React from "react";
import {useNavigate} from "react-router-dom";
import {useQueryClient} from "@tanstack/react-query";

const HomeScreen = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient()


    return (
        <div>

        </div>
    );
};

export default HomeScreen;

const taxes_types = [
    {
        "id": 0,
        "text": "IVA 0%"
    },
    {
        "id": 4,
        "text": "IVA 4%"
    },
    {
        "id": 10,
        "text": "IVA 10%"
    },
    {
        "id": 22,
        "text": "IVA 22%"
    },

]


export default taxes_types;

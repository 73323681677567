const field_types = [
    {
        "id": "ml",
        "text": "linear_meter"
    },
    {
        "id": "mq",
        "text": "square_meter"
    },
    {
        "id": "mc",
        "text": "cube_meter"
    },
    {
        "id": "pz",
        "text": "piece"
    },
    {
        "id": "cad",
        "text": "cadauno"
    },
    {
        "id": "kg",
        "text": "kilogram"
    },
    {
        "id": "l",
        "text": "liter"
    },
    {
        "id": "t",
        "text": "ton"
    },
    {
        "id": "cm",
        "text": "centimeters"
    },
    {
        "id": "mm",
        "text": "millimeters"
    },
    {
        "id": "h",
        "text": "hour"
    },
    {
        "id": "gg",
        "text": "day"
    },
    {
        "id": "sett",
        "text": "week"
    },
    {
        "id": "mesi",
        "text": "month"
    },
    {
        "id": "",
        "text": "custom"
    }
]


export default field_types;

import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {AxiosError} from "axios";
import useApi from "../../../../utils/custom-hooks/api-hooks/useApi";
import {ICategory} from "../../../../utils/interfaces/Catalogue/ICategory";


const useApiGetCategory = (pk: number | undefined): UseQueryResult<ICategory, AxiosError> => {
    const {get} = useApi()

    async function doGet(): Promise<ICategory> {
        try {
            const response = await get(`/catalogue/categories/${pk}/?processings=1`);
            return response.data.categories[0];
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetCategory", pk],
        queryFn: () => doGet(),
        enabled: !!pk,
    })
};

export default useApiGetCategory

import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {AxiosError} from "axios";
import {IProcessing} from "../../../../utils/interfaces/Catalogue/IProcessing";
import {useApp} from "../../../../utils/global-contexts/AppContext";
import useApi from "../../../../utils/custom-hooks/api-hooks/useApi";


const useApiUpdateProcessing = (): UseMutationResult<IProcessing, AxiosError, {
    id: number | undefined;
    data: any
}, unknown> => {
    const queryClient = useQueryClient();
    const {selectedCategory} = useApp();

    const {putEncoded, put} = useApi();

    async function doMutation(pk: number | undefined,
                              data: any): Promise<IProcessing> {
        try {
            const response = await put(`/catalogue/processings/${pk}`, data);
            return response.data.data as IProcessing;
        } catch (error) {
            throw error;
        }
    }

    return useMutation<IProcessing, AxiosError, {
        id: number | undefined;
        data: any
    }, unknown>({
        mutationFn: async ({id, data}) => {
            return await doMutation(id, data);
        },
        onSuccess: async (data) => {
            await queryClient.invalidateQueries({queryKey: ['useApiGetCatalogue']});
            if (selectedCategory) {
                await queryClient.invalidateQueries({queryKey: ['useApiGetCategory', selectedCategory.id]});
            }

        },
    });
};


export default useApiUpdateProcessing;

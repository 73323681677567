import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {AxiosError} from "axios";
import useApi from "../../../../utils/custom-hooks/api-hooks/useApi";
import {ICategory} from "../../../../utils/interfaces/Catalogue/ICategory";


const useApiCreateCategory = (): UseMutationResult<ICategory, AxiosError, {
    data: any
}, unknown> => {
    const queryClient = useQueryClient();

    const {postEncoded} = useApi()

    async function doMutation(data: any): Promise<ICategory> {
        try {
            const response = await postEncoded(`/catalogue/categories`, data);
            return response.data.data as ICategory;
        } catch (error) {
            throw error;
        }
    }

    return useMutation<ICategory, AxiosError, {
        data: any
    }, unknown>({
        mutationFn: async ({data}) => {
            return await doMutation(data);
        },
        onSuccess: async (data) => {
            await queryClient.invalidateQueries({queryKey: ['useApiGetCatalogue']});
        },
    });
};


export default useApiCreateCategory;

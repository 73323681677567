import React, {ReactNode} from "react";
import {Button, ButtonProps as MuiButtonProps} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../utils/global-contexts/AuthContext";
import {useTheme} from "@mui/material/styles";


interface Props extends MuiButtonProps {
    logoutText?: string,
    logoutIcon: ReactNode,
}

const ButtonLogout = ({logoutText = "Logout", logoutIcon, ...props}: Props) => {
    const navigate = useNavigate();
    const {setIsAuthenticated} = useAuth();
    const theme = useTheme();


    const handleLogout = async () => {
        try {
            setIsAuthenticated(false)
            localStorage.clear()
            navigate("/login")
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <Button
            variant={"outlined"}
            endIcon={logoutIcon}
            onClick={handleLogout}
            {...props}
            sx={{color: 'black'}}
        >
            {logoutText}
        </Button>
    )
}

export default ButtonLogout;

const Images = {
    arrow_tab_listino: require("./images/arrow_tab_listino.png"),
    backArrowIcon: require('./images/back.png'),
    cambio_stato: require("./images/cambio_stato.png"),
    consuntivi: require("./images/consuntivi.png"),
    estimates: require("./images/estimates.png"),
    estimatesBlack: require("./images/black/estimates-black.png"),
    estimatesYellow: require("./images/yellow/estimates-yellow.png"),
    estimatesWhite: require("./images/white/estimates.png"),
    filterArrow: require("./images/filter_arrow.png"),
    headphone: require("./images/headphone.png"),
    helmetLogo: require("./images/helmet_logo.png"),
    homeIcon: require('./images/home.png'),
    logoBurgerMenu: require('./images/logo-simple.png'),
    logoHeader: require('./images/logo-header.png'),
    menuEdiliziappHeader: require('./images/logo-header.png'),
    menuIcon: require('./images/burger-menu.png'),
    navbar_dati: require("./images/navbar/navbar_dati.png"),
    navbar_dati_yellow: require("./images/navbar/navbar_dati_yellow.png"),
    navbar_lavorazioni: require("./images/navbar/navbar_lavorazioni.png"),
    navbar_lavorazioni_yellow: require("./images/navbar/navbar_lavorazioni_yellow.png"),
    navbar_listino: require("./images/navbar/navbar_listino.png"),
    navbar_listino_yellow: require("./images/navbar/navbar_listino_yellow.png"),
    navbar_note: require("./images/navbar/navbar_note.png"),
    navbar_note_yellow: require("./images/navbar/navbar_note_yellow.png"),
    navbar_recap: require("./images/navbar/navbar_recap.png"),
    navbar_recap_yellow: require("./images/navbar/navbar_recap_yellow.png"),
    new: require("./images/new.png"),
    new_black: require("./images/black/new-black.png"),
    next: require("./images/next.png"),
    people: require("./images/people.png"),
    peopleWhite: require('./images/white/clienti.png'),
    person: require("./images/person.png"),
    personWhite: require("./images/white/person.png"),
    plus: require("./images/plus.png"),
    plus_yellow: require("./images/yellow/new-yellow.png"),
    share: require("./images/white/share.png"),
    subscription: require("./images/subscription.png"),
    trash: require("./images/trash.png"),
    trash_white: require("./images/white/delete.png"),
    whatsapp_white: require("./images/whatsapp_white.png"),
    telephone_black: require("./images/black/telefono-black.png"),
    telephone_white: require("./images/white/telefono.png"),
    telephone_yellow: require("./images/yellow/telefono-yellow.png"),
    email_black: require("./images/black/email-black.png"),
    email_white: require("./images/white/email.png"),
    email_yellow: require("./images/yellow/email-yellow.png"),
    location: require("./images/white/location.png"),
    location_black: require("./images/black/location-black.png"),
    arrowLeft_white: require("./images/white/arrow-left.png"),
    arrowRight_white: require("./images/white/arrow-right.png"),
    leftWhite: require("./images/white/left.png"),
    leftBlack: require("./images/black/left-black.png"),
    rightWhite: require("./images/white/right.png"),
    rightBlack: require("./images/black/right-black.png"),
    downWhite: require("./images/white/down.png"),
    downYellow: require("./images/yellow/down-yellow.png"),
    upWhite: require("./images/white/up.png"),
    upYellow: require("./images/yellow/up-yellow.png"),
    upBlack: require("./images/black/up-black.png"),
    downBlack: require("./images/black/down-black.png"),
    loading_black: require("./images/black/load-black.png"),
    loading_white: require("./images/white/load-white.png"),
    viewPasswordOpen: require("./images/yellow/down-yellow.png"),
    viewPasswordClose: require("./images/yellow/up-yellow.png"),
    newsBlack: require("./images/black/news.png"),
    newsWhite: require("./images/white/news.png"),
    newsYellow: require("./images/yellow/news.png"),
    eye_openBlack: require("./images/black/eye_open.png"),
    eye_openWhite: require("./images/white/eye_open.png"),
    eye_openYellow: require("./images/yellow/eye_open.png"),
    eye_closeBlack: require("./images/black/eye_close.png"),
    eye_closeWhite: require("./images/white/eye_close.png"),
    eye_closeYellow: require("./images/yellow/eye_close.png"),
    searchBlack: require("./images/black/search.png"),
    searchWhite: require("./images/white/search.png"),
    searchYellow: require("./images/yellow/search.png"),
    transformBlack: require("./images/black/transform.png"),
    transformWhite: require("./images/white/transform.png"),
    transformYellow: require("./images/yellow/transform.png"),
    consuntiviBlack: require("./images/black/consuntivi_1.png"),
    consuntiviWhite: require("./images/white/consuntivi_1.png"),
    consuntiviYellow: require("./images/yellow/consuntivi_1.png"),
    companyBlack: require("./images/black/azienda-black.png"),
    companyYellow: require("./images/yellow/azienda-yellow.png"),
    companyWhite: require("./images/white/azienda.png"),
};

export default Images;

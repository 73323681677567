import React, {useEffect, useState} from "react";
import {Box, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup} from "@mui/material";
import UndoButton from "../../ui/Buttons/UndoButton";
import {FaSave} from "react-icons/fa";
import EdiliziaAppButton from "../../ui/Buttons/EdiliziaAppButton";
import {IClient} from "../../utils/interfaces/Client/IClient";
import useApiCreateClient from "./api-hooks/useApiCreateClient";
import useApiUpdateClient from "./api-hooks/useApiUpdateClient";
import MuiInputTextField from "../../ui/MuiInputData/MuiInputTextField";
import {useApp} from "../../utils/global-contexts/AppContext";
import {useTranslation} from "react-i18next";
import useApiGetUserClient from "./api-hooks/useApiGetUserClient";


interface Props {
    objToEdit: IClient | null | undefined;
    onClose: () => void;
    onSelect?: (obj: IClient | undefined) => void,
}

const ClientForm: React.FC<Props> = ({objToEdit: initialObj, onClose, onSelect}) => {
    const {t} = useTranslation()
    const {userId} = useApp()
    const [name, setName] = useState<string>()
    const [type, setType] = useState<string>('company')
    const [companyName, setCompanyName] = useState<string>()
    const [fiscalCode, setFiscalCode] = useState<string>()
    const [vat, setVat] = useState<string>()
    const [email, setEmail] = useState<string>()
    const [mobile, setMobile] = useState<string>()
    const [phone, setPhone] = useState<string>()
    const [address, setAddress] = useState<string>()
    const [zip, setZip] = useState<string>()
    const [city, setCity] = useState<string>()
    const [province, setProvince] = useState<string>()
    const [pec, setPec] = useState<string>()
    const [sdi, setSdi] = useState<string>()


    const createMutation = useApiCreateClient();
    const updateMutation = useApiUpdateClient();

    const handleSubmit = async () => {
        try {
            const payload = {
                name: name,
                type: type,
                user: userId,
                company_name: companyName,
                fiscal_code: fiscalCode,
                vat: vat,
                email: email || '',
                mobile: mobile || '',
                phone: phone,
                address: address,
                zip: zip,
                city: city,
                province: province,
                pec: pec,
                sdi: sdi,
            };

            let newData;

            if (initialObj !== null && initialObj !== undefined) {
                newData = await updateMutation.mutateAsync({id: initialObj.id, data: payload});
            } else {
                newData = await createMutation.mutateAsync({data: payload});
            }
            onClose();
            onSelect && onSelect(newData);

        } catch (error) {
        }
    }

    const {
        data: detailedObj,
    } = useApiGetUserClient(userId, initialObj?.id);

    useEffect(() => {
        if (detailedObj) {
            setName(detailedObj.name)
            setType(detailedObj.type)
            setCompanyName(detailedObj.company_name)
            setFiscalCode(detailedObj.fiscal_code)
            setVat(detailedObj.vat)
            setCompanyName(detailedObj.company_name)
            setFiscalCode(detailedObj.fiscal_code)
            setVat(detailedObj.vat)
            setEmail(detailedObj.email)
            setMobile(detailedObj.mobile)
            setPhone(detailedObj.phone)
            setAddress(detailedObj.address)
            setZip(detailedObj.zip)
            setCity(detailedObj.city)
            setProvince(detailedObj.province)
            setPec(detailedObj.pec)
            setSdi(detailedObj.sdi)
        }
    }, [detailedObj]);


    const [allRequirementAreSatisfied, setAllRequirementAreSatisfied] = useState(false);
    useEffect(() => {
        setAllRequirementAreSatisfied(false)
        setAllRequirementAreSatisfied(true);

    }, []);

    const x_grid = 6


    return (
        <Box>

            <Grid container spacing={1}>
                <Grid item xs={x_grid}>
                    <MuiInputTextField
                        onChange={(e) => setName(e.target.value)}
                        label='name'
                        type="text"
                        value={name || ''}
                    />

                    <FormControl component="fieldset">
                        <FormLabel component="legend">{t("client type").toUpperCase()}</FormLabel>
                        <RadioGroup
                            aria-label="type"
                            name="radio-buttons-group"
                            value={type}
                            onChange={(event) => setType(event.target.value)}
                        >
                            <FormControlLabel value="private" control={<Radio/>} label={t("private")}/>
                            <FormControlLabel value="company" control={<Radio/>} label={t("company")}/>
                        </RadioGroup>
                    </FormControl>
                </Grid>

                <Grid item xs={x_grid}>
                    <MuiInputTextField
                        onChange={(e) => setCompanyName(e.target.value)}
                        label='company name'
                        type="text"
                        value={companyName || ''}
                    />
                    <MuiInputTextField
                        onChange={(e) => setFiscalCode(e.target.value)}
                        label='fiscal code'
                        type="text"
                        value={fiscalCode || ''}
                    />
                    <MuiInputTextField
                        onChange={(e) => setVat(e.target.value)}
                        label='vat'
                        type="text"
                        value={vat || ''}
                    />
                </Grid>

                <Grid item xs={x_grid}>
                    <MuiInputTextField
                        onChange={(e) => setEmail(e.target.value)}
                        label='email'
                        type="text"
                        value={email || ''}
                    />
                    <MuiInputTextField
                        onChange={(e) => setPhone(e.target.value)}
                        label='phone'
                        type="text"
                        value={phone || ''}
                    />
                    <MuiInputTextField
                        onChange={(e) => setMobile(e.target.value)}
                        label='mobile'
                        type="text"
                        value={mobile || ''}
                    />
                </Grid>

                <Grid item xs={x_grid}>
                    <MuiInputTextField
                        onChange={(e) => setAddress(e.target.value)}
                        label='address'
                        type="text"
                        value={address || ''}
                    />
                    <MuiInputTextField
                        onChange={(e) => setCity(e.target.value)}
                        label='city'
                        type="text"
                        value={city || ''}
                    />
                    <MuiInputTextField
                        onChange={(e) => setZip(e.target.value)}
                        label='zip'
                        type="text"
                        value={zip || ''}
                    />
                </Grid>

                <Grid item xs={x_grid}>
                    <MuiInputTextField
                        onChange={(e) => setPec(e.target.value)}
                        label='pec'
                        type="text"
                        value={pec || ''}
                    />
                    <MuiInputTextField
                        onChange={(e) => setSdi(e.target.value)}
                        label='sdi'
                        type="text"
                        value={sdi || ''}
                    />
                </Grid>

            </Grid>


            <Box style={{display: 'flex', justifyContent: 'center', gap: '4px', marginTop: '24px'}}>
                <UndoButton onClose={onClose}/>
                {allRequirementAreSatisfied &&
                    <EdiliziaAppButton label={'save'} icon={<FaSave/>} onClick={handleSubmit}/>
                }
            </Box>

        </Box>
    );
};

export default ClientForm;

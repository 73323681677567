import {useTheme} from "@mui/material/styles";
import {useApp} from "../../../utils/global-contexts/AppContext";
import React, {useEffect, useMemo, useState} from "react";
import {MaterialReactTable, MRT_ActionMenuItem, MRT_ColumnDef, useMaterialReactTable} from "material-react-table";
import {
    getMuiTableBodyCellProps,
    getMuiTableBodyRowProps,
    getMuiTableHeadCellProps,
    getMuiTablePaperProps
} from "../../../ui/MuiReactTable/MuiReactTableStyles";
import {Avatar, Box, Grid, Tooltip} from "@mui/material";
import MuiReadOnlyBoxBody from "../../../ui/MuiDisplayData/MuiReadOnlyBoxBody";
import MRTMuiButton from "../../../ui/MuiReactTable/MRTMuiButton";
import Images from "../../../assets/images";
import {Add, Category, Delete, Edit} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import useCustomCellRenderer from "./useCustomCellRenderer";
import {IProcessing} from "../../../utils/interfaces/Catalogue/IProcessing";
import MuiModal from "../../../ui/MuiModals/MuiModal";
import ProcessingCategoryChanger from "./ProcessingCategoryChanger";
import UndoButton from "../../../ui/Buttons/UndoButton";
import useApiGetCategory from "../Category/api-hooks/useApiGetCategory";
import ProcessingForm from "./ProcessingForm";
import useApiDeleteProcessing from "./api-hooks/useApiDeleteProcessing";
import MuiAlertDialog from "../../../ui/MuiAlertDialog";
import useApiGetCatalogue from "../api-hooks/useApiGetCatalogue";
import useApiCreateEstimateUserField from "../../UserField/api-hooks/useApiCreateEstimateUserField";
import useApiCreateFinalBalanceUserField from "../../UserField/api-hooks/useApiCreateFinalBalanceUserField";
import {UseMutationResult} from "@tanstack/react-query";
import {IUserField} from "../../../utils/interfaces/Estimate/IUserField";
import {AxiosError} from "axios";
import {useLocation} from "react-router-dom";


interface Props {
    onClose?: () => void;
}


const ProcessingTable = ({onClose}: Props) => {
    const theme = useTheme();
    const {t} = useTranslation()
    const {selectedCategory, selectedEstimate, selectedFinalBalance} = useApp();
    const {pathname} = useLocation()

    const [selectedRow, setSelectedRow] = useState<IProcessing | undefined>()
    const [rows, setRows] = useState<IProcessing[] | undefined>()

    const createUserFieldMutationToEstimate = useApiCreateEstimateUserField(selectedEstimate?.id)
    const createUserFieldMutationToFinalBalance = useApiCreateFinalBalanceUserField(selectedFinalBalance?.id)

    const handleSubmitCreateMutation = async (row: IProcessing, mutation: UseMutationResult<IUserField, AxiosError<unknown, any>, {
        data: any;
    }, unknown>) => {
        try {
            const payload = {
                label: row.label,
                value: row.value,
                type: row.type,
                description: row.description,
            };
            await mutation.mutateAsync({data: payload});

        } catch (error) {
        }
    }

    const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false)
    const [isCategoryChangerModalOpen, setIsCategoryChangerModalOpen] = useState<boolean>(false)

    const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const deleteMutation = useApiDeleteProcessing();
    const {mutateAsync: apiDelete} = deleteMutation;
    const handleConfirmDelete = async () => {
        try {
            await apiDelete({pk: selectedRow?.id});
        } catch (error) {
        }
        setDeleteConfirmationOpen(false);
    };
    const handleCancelDelete = () => {
        setDeleteConfirmationOpen(false);
    };


    const {data: singleCategory, refetch: refetchSingleCategory} = useApiGetCategory(selectedCategory?.id)
    useEffect(() => {
        if (singleCategory && selectedCategory) {
            setRows(singleCategory.processings)
        }
    }, [selectedCategory, singleCategory]);

    const {data: fullCatalogue, refetch: refetchCatalogue} = useApiGetCatalogue();
    useEffect(() => {
        if (fullCatalogue && !selectedCategory) {
            setRows(fullCatalogue.uncategorized);
        }
    }, [fullCatalogue, selectedCategory]);


    const CustomCellRenderer: React.FC<{ row: IProcessing, theme: any }> = ({row, theme}) => {
        const {renderIcons} = useCustomCellRenderer(row, theme);
        return renderIcons();
    };


    const columns = useMemo<MRT_ColumnDef<IProcessing>[]>(
        () => [
            {
                id: "custom_actions",
                header: t(""),
                columnDefType: "display",
                Cell: ({row}) => (
                    <Box sx={{display: 'flex'}}>
                        {selectedEstimate && (pathname === '/catalogue' || pathname === '/estimate') &&
                            <Tooltip title={t("insert in Estimate n.") + selectedEstimate.number}>
                                <Avatar sx={{
                                    backgroundColor: 'black',
                                    border: `2px solid ${(theme as any).palette.primary.main}`,
                                    marginRight: 1,
                                }}>
                                    <img src={Images.estimatesYellow} alt={''}
                                         style={{width: '24px', height: '24px', cursor: 'pointer'}}
                                         onClick={
                                             () => {
                                                 handleSubmitCreateMutation(row.original, createUserFieldMutationToEstimate);
                                             }
                                         }
                                    />
                                </Avatar>
                            </Tooltip>
                        }
                        {selectedFinalBalance && (pathname === '/catalogue' || pathname === '/final_balance') &&
                            <Tooltip title={t("insert in Final Balance n.") + selectedFinalBalance.number}>
                                <Avatar sx={{
                                    backgroundColor: 'black',
                                    border: `2px solid ${(theme as any).palette.primary.main}`,
                                    marginRight: 1,
                                }}>
                                    <img src={Images.consuntiviYellow} alt={''}
                                         style={{width: '24px', height: '24px', cursor: 'pointer'}}
                                         onClick={
                                             () => {
                                                 handleSubmitCreateMutation(row.original, createUserFieldMutationToFinalBalance);
                                             }
                                         }
                                    />
                                </Avatar>
                            </Tooltip>
                        }
                    </Box>
                )
            },
            {
                accessorKey: 'label',
                header: t('label'),
                filterFn: 'contains',
            },
            {
                accessorKey: 'type',
                header: t('field type'),
                filterFn: 'contains',
            },
            {
                accessorKey: 'value',
                header: t('value'),
            },
            {
                accessorKey: 'description',
                header: t('description'),
                filterFn: 'contains',
            },

        ],
        []
    );


    const table = useMaterialReactTable<IProcessing>({
        columns,
        defaultColumn: {
            maxSize: 400,
            minSize: 80,
            size: 80,
        },
        data: rows ? rows : [],
        columnFilterDisplayMode: 'popover',
        globalFilterFn: 'contains',
        enableFullScreenToggle: false,
        enableFilters: true,
        enableDensityToggle: true,
        muiTableHeadCellProps: getMuiTableHeadCellProps(theme.palette.primary.main),
        muiTableBodyCellProps: getMuiTableBodyCellProps(theme),
        muiTablePaperProps: getMuiTablePaperProps,
        enablePagination: rows ? rows?.length > 25 : false,
        initialState: {
            density: 'compact',
            pagination: {pageSize: 25, pageIndex: 0},
        },

        mrtTheme: (theme) => ({
            baseBackgroundColor: theme.palette.background.default,

        }),
        muiTableBodyRowProps: getMuiTableBodyRowProps(theme),
        renderTopToolbarCustomActions: ({table}) => (
            <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                <>
                    <Avatar sx={{
                        backgroundColor: 'black',
                        border: `2px solid ${theme.palette.primary.main}`,
                        marginRight: 1
                    }}>
                        <img src={Images.navbar_lavorazioni_yellow} alt={'processings'}
                             style={{width: '24px', height: '24px'}}/>
                    </Avatar>
                    <MuiReadOnlyBoxBody label={''} value={'processings'}/>
                    <MRTMuiButton
                        icon={<Add style={{color: theme.palette.primary.main, fontSize: '2rem'}}/>}
                        onClick={() => {
                            setSelectedRow(undefined);
                            setIsEditFormOpen(true)
                        }} label={t('add processing')}/>

                </>
            </Box>
        ),

        enableRowActions: true,
        renderRowActionMenuItems: ({closeMenu, staticRowIndex, row}) => [
            <MRT_ActionMenuItem
                icon={<Category color={'success'}/>}
                key="set category"
                label={t("set category")}
                onClick={(event) => {
                    setSelectedRow(row.original);
                    setIsCategoryChangerModalOpen(true)
                    closeMenu()
                }}
                table={table}
            />,
            <MRT_ActionMenuItem
                icon={<Edit color="primary"/>}
                key="edit"
                label={t("edit")}
                onClick={(event) => {
                    setSelectedRow(row.original);
                    setIsEditFormOpen(true)
                    closeMenu()
                }}
                table={table}
            />,
            <MRT_ActionMenuItem
                icon={<Delete color="warning"/>}
                key="delete"
                label={t("delete")}
                onClick={(event) => {
                    setSelectedRow(row.original);
                    setDeleteConfirmationOpen(true);
                    closeMenu()
                }}
                table={table}
            />,
        ],
    });


    return <>
        {onClose &&
            <Grid item xs={12} style={{textAlign: 'center'}}>
                <Box className={'mt-2 gap-x-2 mb-2'}>
                    <UndoButton onClose={onClose}/>
                </Box>
            </Grid>}

        {selectedCategory &&
            <Box style={{display: 'flex', justifyContent: 'center', gap: '4px', marginTop: '16px'}}>
                <Avatar sx={{
                    backgroundColor: 'black',
                    border: `2px solid ${theme.palette.primary.main}`,
                    marginRight: 1
                }}>
                    <img src={Images.navbar_listino_yellow} alt={'processings'}
                         style={{width: '24px', height: '24px'}}/>
                </Avatar>
                <MuiReadOnlyBoxBody label={''} value={selectedCategory.label}/>
            </Box>}

        <MaterialReactTable table={table}/>

        <MuiModal
            top="50%"
            left="50%"
            width={'50vH'}
            open={isCategoryChangerModalOpen}
            onClose={() => setIsCategoryChangerModalOpen(false)}
            disableBackdropClick={true}
        >
            <ProcessingCategoryChanger
                onClose={() => setIsCategoryChangerModalOpen(false)}
                obj={selectedRow}
            />
        </MuiModal>

        <MuiModal
            top="50%"
            left="50%"
            width={'100vH'}
            open={isEditFormOpen}
            onClose={() => setIsEditFormOpen(false)}
            disableBackdropClick={true}
        >
            <ProcessingForm
                objToEdit={selectedRow}
                onClose={() => setIsEditFormOpen(false)}
            />
        </MuiModal>

        <MuiAlertDialog
            open={isDeleteConfirmationOpen}
            title="Delete Confirmation"
            content="Are you sure you want to delete this processing?"
            onClose={handleCancelDelete}
            onConfirm={handleConfirmDelete}
        />
    </>;

};

export default ProcessingTable;

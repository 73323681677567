import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Box, TextField} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import MuiAutocomplete from "../../ui/MuiAutocomplete";
import {ITaxesType} from "./ITaxesType";
import taxes_types from "./taxes_types";


interface Props {
    obj: ITaxesType | undefined,
    onChange: (obj: ITaxesType | null) => void,
    className: string,
    label?: string,
    required?: boolean
}

const FieldTypeSelect = ({obj: initialObj, onChange, label, required}: Props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const [objs, setObjs] = useState<ITaxesType[]>([]);
    const [obj, setObj] = useState<ITaxesType | null | undefined>(initialObj);


    useEffect(() => {
        setObj(initialObj);
    }, [initialObj]);


    useEffect(() => {
        if (taxes_types) {
            const sortedTaxesTypes = [...taxes_types].sort((a, b) => a.id - b.id);
            setObjs(sortedTaxesTypes);
        }
    }, [taxes_types]);


    const handleOnChange = (event: React.SyntheticEvent, newValue: ITaxesType | null) => {
        onChange(newValue);
    };


    return (
        <>
            <Box sx={{alignItems: 'center', width: '100%'}}>
                <MuiAutocomplete
                    id="taxes_type-select"
                    options={objs}
                    getOptionLabel={(option) => option.text}
                    value={obj ? obj : null}
                    onChange={handleOnChange}
                    isOptionEqualToValue={(option, value) => option.id === value?.id && option.text === value?.text}
                    renderInput={(params) => (
                        <TextField
                            sx={{textTransform: 'capitalize'}}
                            {...params}
                            label={label || t('select taxes type')}
                            required={required}
                        />
                    )}
                ></MuiAutocomplete>
            </Box>
        </>
    );
}

export default FieldTypeSelect;

import React from "react";
import FinalBalanceTable from "./FinalBalanceTable";

const FinalBalanceScreen = () => {

    return (
        <FinalBalanceTable/>
    );
};


export default FinalBalanceScreen;

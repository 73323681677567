import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {AxiosError} from "axios";
import useApi from "../../../utils/custom-hooks/api-hooks/useApi";
import {useApp} from "../../../utils/global-contexts/AppContext";
import {IFinalBalance} from "../../../utils/interfaces/FinalBalance/IFinalBalance";


const useApiUpdateFinalBalance = (): UseMutationResult<IFinalBalance, AxiosError, {
    id: number | undefined;
    data: any
}, unknown> => {
    const queryClient = useQueryClient();
    const {userId} = useApp()

    const {putEncoded, put} = useApi()

    async function doMutation(pk: number | undefined,
                              data: any): Promise<IFinalBalance> {
        try {
            const response = await put(`/finalbalances/${pk}`, data);
            return response.data.data as IFinalBalance;
        } catch (error) {
            throw error;
        }
    }

    return useMutation<IFinalBalance, AxiosError, {
        id: number | undefined;
        data: any
    }, unknown>({
        mutationFn: async ({id, data}) => {
            return await doMutation(id, data);
        },
        onSuccess: async (data) => {
            await queryClient.invalidateQueries({queryKey: ['useApiGetUserFinalBalances', userId]});
        },
    });
};


export default useApiUpdateFinalBalance;

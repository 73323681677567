import React, {useEffect, useState} from 'react';
import {Box, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {IconContext} from "react-icons";
import {VscClose} from "react-icons/vsc";
import {contentNavbarEntries} from "./content-navbar";
import {useTheme} from '@mui/material/styles';
import Images from "../../assets/images";

interface Props {
    isOpen?: boolean,
    handleToggle?: () => void,
    setAppbarTitle: (title: string) => void,
}

const NavBar = ({isOpen = false, handleToggle, setAppbarTitle}: Props) => {
    const navigate = useNavigate();
    const theme = useTheme();

    // Default highlighted page is set here manually
    const [selectedIndex, setSelectedIndex] = useState<number>(0);

    useEffect(() => {
        setAppbarTitle("Home");
    }, [setAppbarTitle]);

    const handleNavbarNavigation = (title: string, to: string, i: number) => {
        setAppbarTitle(title);
        setSelectedIndex(i);

        // If first character different from "/"
        if (to[0] !== "/") {
            window.location.href = to;
        } else {
            navigate(to);
            handleToggle && handleToggle();
        }
    };

    return (
        <Drawer
            variant={"persistent"}
            open={isOpen}
        >
            <IconContext.Provider value={{color: "white", size: "20px"}}>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        py: 2.45,
                        borderBottom: "1px solid",
                        borderColor: "white",
                        background: theme.palette.greenSoft.main
                    }}
                >
                    <img src={Images.logoHeader} alt={'main_logo'} style={{width: '50%', height: 'auto'}}/>


                    <IconButton
                        onClick={handleToggle}
                        sx={{marginRight: 2}}
                    >
                        <VscClose size={24}/>
                    </IconButton>
                </Box>
                <Box
                    sx={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        alignItems: "center",
                        background: `linear-gradient(to bottom, ${theme.palette.greenSoft.main}, ${theme.palette.greenDarker.main})`
                    }}
                >
                    <List sx={{width: "100%"}}>
                        {contentNavbarEntries.map((entryObj: any, index: number) => (
                            <React.Fragment key={index}>
                                <ListItem>
                                    <ListItemButton
                                        sx={{
                                            color: 'white',
                                            backgroundColor: selectedIndex === index ? "greenSoft.dark" : "",
                                            ":hover": {
                                                backgroundColor: selectedIndex === index ? "greenSoft.dark" : "",
                                            }
                                        }}
                                        onClick={() => {
                                            handleNavbarNavigation(entryObj.name, entryObj.to, index);
                                        }}
                                    >
                                        <ListItemIcon>
                                            {entryObj.icon}
                                        </ListItemIcon>
                                        <ListItemText
                                            sx={{textTransform: 'uppercase'}}
                                            primary={entryObj.name}
                                        />
                                    </ListItemButton>
                                </ListItem>
                            </React.Fragment>
                        ))}
                    </List>
                </Box>
            </IconContext.Provider>
        </Drawer>
    );
};

export default NavBar;

import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {AxiosError} from "axios";
import {IUserDetailed} from "../../../utils/interfaces/Profile/IUserDetailed";
import useApi from "../../../utils/custom-hooks/api-hooks/useApi";


const useApiGetUser = (pk: number | undefined): UseQueryResult<IUserDetailed, AxiosError> => {
    const {get} = useApi()

    async function doGet(pk: number | undefined): Promise<IUserDetailed> {
        try {
            const response = await get(`/users/${pk}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetUser", pk],
        queryFn: () => doGet(pk),
        enabled: !!pk
    })
};

export default useApiGetUser

import {FC} from 'react';
import {useTheme} from '@mui/material/styles';
import EdiliziaAppButton from "./EdiliziaAppButton";
import {GiConfirmed} from "react-icons/gi";

interface UndoButtonProps {
    onConfirm: () => void;
}

const ConfirmButton: FC<UndoButtonProps> = ({onConfirm}) => {
    const theme = useTheme();

    return (
        <EdiliziaAppButton label={'confirm'}
                           icon={<GiConfirmed/>}
                           custom_color={theme.palette.primary.main}
                           onClick={onConfirm}/>
    );
}

export default ConfirmButton;

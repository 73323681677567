import React from "react";
import ClientTable from "./ClientTable";


const ClientScreen = () => {


    return (
        <ClientTable/>

    );
};


export default ClientScreen;

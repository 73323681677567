import React, {FormEvent, useState} from 'react';
import {useNavigate} from "react-router-dom";
import usePostLogin from "./api-hooks/usePostLogin";
import {Button, LinearProgress, Paper, Stack, TextField, Typography} from "@mui/material";
import {RiLoginBoxLine} from "react-icons/ri";
import {useAuth} from "../../utils/global-contexts/AuthContext";
import {useResponseMessagesContext} from "../../utils/global-contexts/ResponseMessageContext";
import {useApp} from "../../utils/global-contexts/AppContext";

const LoginForm = () => {
    const navigate = useNavigate();
    const {setIsAuthenticated} = useAuth();
    const {setUserId} = useApp()
    const {addMessage} = useResponseMessagesContext();

    const [loading, setLoading] = useState(false);

    const {mutateAsync: loginMutation} = usePostLogin();

    const signIn = async (username: string, password: string) => {

        try {
            const response = await loginMutation({
                email: username,
                password: password,
            });
            if (response.status === 'ok') {
                localStorage.setItem('@access_token', response.token);
                setUserId(response.user)
                setIsAuthenticated(true);
                navigate("/")
            }
        } catch (error) {
        }
    }

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);

        let user = e.currentTarget.user.value;
        let password = e.currentTarget.password.value;

        if (user && password) {
            await signIn(user, password);
        }
        setLoading(false);
    }

    return (
        <Paper
            sx={{
                p: 4,
                borderRadius: 4,
                mt: '2rem',
            }}
            component="form"
            onSubmit={handleSubmit}
        >
            <Typography
                sx={{
                    fontWeight: 400,
                    textTransform: 'uppercase',
                    fontSize: '1rem',
                    color: '#8a8a8a',
                    textAlign: 'center',
                    pb: 2,
                }}
            >
                Effettua l'accesso
            </Typography>
            <Stack gap={1}>
                <TextField label="Utente" name="user" required={true}/>
                <TextField label="Password" name="password" required={true} type="password"/>
                <div>
                    {loading
                        ? <div>
                            <Typography>Accesso in corso...</Typography>
                            <LinearProgress/>
                        </div>
                        : <Button
                            sx={{width: '100%', mt: 2}}
                            type="submit"
                            variant="contained"
                            startIcon={<RiLoginBoxLine/>}
                        >
                            Accedi
                        </Button>
                    }
                </div>
            </Stack>
        </Paper>
    );
};

export default LoginForm;

import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import {useTheme} from "@mui/material/styles";

interface MuiAutocompleteProps<T> {
    id: string;
    options: T[];
    getOptionLabel: (option: T) => string;
    onChange: (event: React.SyntheticEvent, newValue: T | null) => void;
    isOptionEqualToValue: (option: T, value: T | null) => boolean;
    renderInput: (params: any) => JSX.Element;
    value: T | null;
    borderColor?: string;
    onFocusBorderColor?: string;
    isDisabled?: boolean;
    disableClearable?: boolean;
    minWidth?: number;
    children?: React.ReactNode;
}

const MuiAutocomplete = <T, >({
                                  id,
                                  options,
                                  getOptionLabel,
                                  onChange,
                                  isOptionEqualToValue,
                                  renderInput,
                                  value,
                                  borderColor,
                                  onFocusBorderColor,
                                  isDisabled = false,
                                  disableClearable = false,
                                  minWidth,
                                  children
                              }: MuiAutocompleteProps<T>) => {
    const theme = useTheme();
    const _borderColor = borderColor ? borderColor : theme.palette.primary.light;
    const _onFocusBorderColor = onFocusBorderColor ? onFocusBorderColor : theme.palette.primary.dark;

    return (
        <Box sx={{position: 'relative', width: '100%'}}>
            {children && (
                <Box sx={{position: 'absolute', top: -20, right: 0, display: 'flex', alignItems: 'center', gap: 2}}
                >
                    {children}
                </Box>
            )}
            <Box sx={{marginTop: children ? '24px' : 0}}>
                <Autocomplete
                    id={id}
                    options={options}
                    getOptionLabel={getOptionLabel}
                    onChange={onChange}
                    isOptionEqualToValue={isOptionEqualToValue}
                    renderInput={renderInput}
                    value={value}
                    disabled={isDisabled}
                    disableClearable={disableClearable}
                    sx={{
                        minWidth: minWidth,
                        bgcolor: 'white',
                        width: '100%',
                        marginY: 0.4,
                        '& label.Mui-focused': {
                            color: _borderColor,
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderWidth: 2,
                            borderColor: _borderColor,
                        },
                        '& .MuiInput-underline:after': {
                            borderBottomColor: _onFocusBorderColor,
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: _onFocusBorderColor,
                        },
                        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: _borderColor,
                        },
                    }}
                />
            </Box>
        </Box>
    );
};

export default MuiAutocomplete;

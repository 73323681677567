import React from "react";
import EstimateStatusBar from "./EstimateStatusBar";
import {MRT_Row} from "material-react-table";
import {IEstimate} from "../../utils/interfaces/Estimate/IEstimate";
import useApiUpdateEstimate from "./api-hooks/useApiUpdateEstimate";
import {useQueryClient} from "@tanstack/react-query";
import {useApp} from "../../utils/global-contexts/AppContext";

interface Props {
    onClose: () => void
    estimates: MRT_Row<IEstimate>[]
}

const EstimateStatusChanger: React.FC<Props> = ({onClose, estimates}) => {
    const updateMutation = useApiUpdateEstimate();
    const queryClient = useQueryClient();
    const {userId} = useApp()


    const handleClick = async (status: number | null) => {

        if (status === null) {
            return
        }

        const updatePromises = estimates.map(estimate => updateMutation.mutateAsync({
            id: estimate.original.id,
            data: {status}
        }));

        try {
            await Promise.all(updatePromises);
        } catch (error) {
        }
        onClose();
        await queryClient.invalidateQueries({queryKey: ['useApiGetUserEstimates', userId]});

    };

    return (
        <>
            <EstimateStatusBar
                onStatusChange={handleClick}
                label={'select status'}
                allowDeselect={false}
            />
        </>
    );
};

export default EstimateStatusChanger;

import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {AxiosError} from "axios";
import useApi from "../../../utils/custom-hooks/api-hooks/useApi";
import {useApp} from "../../../utils/global-contexts/AppContext";
import {IClient} from "../../../utils/interfaces/Client/IClient";


const useApiCreateClient = (): UseMutationResult<IClient, AxiosError, {
    data: any
}, unknown> => {
    const queryClient = useQueryClient();
    const {userId, selectedEstimate} = useApp()


    const {postEncoded} = useApi()

    async function doMutation(data: any): Promise<IClient> {
        console.log(data)
        try {
            const response = await postEncoded(`/clients/`, data);
            return response.data.data as IClient;
        } catch (error) {
            throw error;
        }
    }

    return useMutation<IClient, AxiosError, {
        data: any
    }, unknown>({
        mutationFn: async ({data}) => {
            return await doMutation(data);
        },
        onSuccess: async (data) => {
            await queryClient.invalidateQueries({queryKey: ['useApiGetUserClients', userId]});
            await queryClient.invalidateQueries({queryKey: ['useApiGetEstimate', selectedEstimate?.id]});
        },
    });
};


export default useApiCreateClient;

import React, {useEffect, useState} from 'react';
import NavBar from "./NavBar";
import {Box, Stack} from "@mui/material";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import TopBar from "./TopBar";
import {useAuth} from "../../utils/global-contexts/AuthContext";
import ProtectedRoute from "../../navigation/ProtectedRoute";
import {useQueryClient} from "@tanstack/react-query";
import {useTranslation} from "react-i18next";


const ProtectedRoutesLayout = () => {
    const {isAuthenticated, setIsAuthenticated} = useAuth();
    const navigate = useNavigate();
    const [isNavBarOpen, setIsNavBarOpen] = useState<boolean>(false);
    const [pageTitle, setPageTitle] = useState<string>("")
    const location = useLocation();
    const queryClient = useQueryClient();
    const {i18n} = useTranslation();


    const handleToggleNavBar = () => {
        setIsNavBarOpen(!isNavBarOpen)
    }

    useEffect(() => {
        queryClient.invalidateQueries({queryKey: ["whoAmI"]})
    }, [location.pathname]);


    return (
        <ProtectedRoute>
            <>
                {isAuthenticated &&
                    <NavBar
                        isOpen={isNavBarOpen}
                        handleToggle={handleToggleNavBar}
                        setAppbarTitle={setPageTitle}
                    />
                }
                <Stack>
                    {isAuthenticated && <TopBar toggleNavBar={handleToggleNavBar} pageTitle={pageTitle}/>}
                    <Box mt={8}> {/* Adjust the margin top value as needed */}
                        <Outlet/>
                    </Box>
                </Stack>
            </>

        </ProtectedRoute>
    );
};

export default ProtectedRoutesLayout;

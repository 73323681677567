import React from "react";
import EstimateTable from "./EstimateTable";

const EstimateScreen = () => {

    return (
        <EstimateTable/>

    );
};


export default EstimateScreen;

import React, {createContext, ReactNode, useContext, useEffect, useState} from 'react';
import {Alert, Slide, Snackbar} from '@mui/material';

interface Imessage {
    message: string;
    severity?: 'info' | 'error' | 'success' | 'warning';
}

interface IResponseMessagesContext {
    messages: Imessage[];
    addMessage: (newMessage: Imessage) => void;
    clearMessages: () => void;
}

const ResponseMessagesContext = createContext<IResponseMessagesContext | undefined>(undefined);

export const useResponseMessagesContext = () => {
    const context = useContext(ResponseMessagesContext);
    if (!context) {
        throw new Error('useResponseMessagesContext must be used within a ResponseMessagesProvider');
    }
    return context;
};

interface ResponseMessagesProviderProps {
    children: ReactNode;
}

export const ResponseMessagesProvider = ({children}: ResponseMessagesProviderProps) => {
    const [messages, setMessages] = useState<Imessage[]>([]);
    const [open, setOpen] = useState<boolean>(false);
    const [currentMessage, setCurrentMessage] = useState<Imessage | null>(null);

    const addMessage = (newMessage: Imessage) => {
        setMessages(prevMessages => [...prevMessages, {...newMessage, severity: newMessage.severity || 'error'}]);
    };

    const clearMessages = () => {
        setMessages([]);
    };

    useEffect(() => {
        if (messages.length > 0 && !currentMessage) {
            setCurrentMessage(messages[0]);
            setMessages(prevMessages => prevMessages.slice(1));
            setOpen(true);
        } else if (messages.length > 0 && currentMessage && !open) {
            setCurrentMessage(messages[0]);
            setMessages(prevMessages => prevMessages.slice(1));
            setOpen(true);
        }
    }, [messages, currentMessage, open]);

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const transition = (props: any) => {
        return <Slide {...props} direction="up"/>;
    };

    return (
        <ResponseMessagesContext.Provider value={{messages, addMessage, clearMessages}}>
            {children}
            <Snackbar
                open={open}
                autoHideDuration={3000}
                onClose={handleClose}
                TransitionComponent={transition}
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
            >
                <Alert onClose={handleClose}
                       variant="filled"
                       severity={currentMessage?.severity || 'error'} sx={{width: '100%'}}>
                    {currentMessage?.message}
                </Alert>
            </Snackbar>
        </ResponseMessagesContext.Provider>
    );
};

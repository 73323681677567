import React, {useContext, useState} from "react";
import {IUserDetailed} from "../interfaces/Profile/IUserDetailed";
import {IEstimate} from "../interfaces/Estimate/IEstimate";
import {ICategory} from "../interfaces/Catalogue/ICategory";
import {IFinalBalance} from "../interfaces/FinalBalance/IFinalBalance";

type ProviderProps = {
    children: React.ReactNode,
}

type AppContextType = {
    userId: number | undefined,
    setUserId: React.Dispatch<React.SetStateAction<number | undefined>>,
    user: IUserDetailed | undefined,
    setUser: React.Dispatch<React.SetStateAction<IUserDetailed | undefined>>,
    userImage: string | undefined,
    setUserImage: React.Dispatch<React.SetStateAction<string | undefined>>,
    selectedEstimate: IEstimate | undefined,
    setSelectedEstimate: React.Dispatch<React.SetStateAction<IEstimate | undefined>>,
    selectedCategory: ICategory | undefined,
    setSelectedCategory: React.Dispatch<React.SetStateAction<ICategory | undefined>>,
    selectedFinalBalance: IFinalBalance | undefined,
    setSelectedFinalBalance: React.Dispatch<React.SetStateAction<IFinalBalance | undefined>>,
}

const defaultAppContextValue: AppContextType = {
    userId: undefined,
    setUserId: () => {
    },
    user: undefined,
    setUser: () => {
    },
    userImage: undefined,
    setUserImage: () => {
    },
    selectedEstimate: undefined,
    setSelectedEstimate: () => {
    },
    selectedCategory: undefined,
    setSelectedCategory: () => {
    },
    selectedFinalBalance: undefined,
    setSelectedFinalBalance: () => {
    },
}

const appContext = React.createContext<AppContextType>(defaultAppContextValue);

function useApp() {
    const context = useContext(appContext);
    if (!context) {
        throw new Error('useApp must be used within an appProvider');
    }
    return context;
}

function useProvideApp() {
    const [userId, setUserId] = useState<number | undefined>();
    const [user, setUser] = useState<IUserDetailed | undefined>();
    const [userImage, setUserImage] = useState<string | undefined>();
    const [selectedEstimate, setSelectedEstimate] = useState<IEstimate | undefined>();
    const [selectedCategory, setSelectedCategory] = useState<ICategory | undefined>();
    const [selectedFinalBalance, setSelectedFinalBalance] = useState<IFinalBalance | undefined>();

    return {
        userId, setUserId,
        user, setUser,
        userImage, setUserImage,
        selectedEstimate, setSelectedEstimate,
        selectedCategory, setSelectedCategory,
        selectedFinalBalance, setSelectedFinalBalance,
    }
}

function ProvideAApp({children}: ProviderProps) {
    const auth = useProvideApp();
    return (
        <appContext.Provider value={auth}>
            {children}
        </appContext.Provider>
    )
}

export {
    ProvideAApp,
    useApp
}

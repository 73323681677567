import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {AxiosError} from "axios";
import useApi from "../../../../utils/custom-hooks/api-hooks/useApi";
import {ICategory} from "../../../../utils/interfaces/Catalogue/ICategory";


const useApiUpdateCategory = (): UseMutationResult<ICategory, AxiosError, {
    id: number | undefined;
    data: any
}, unknown> => {
    const queryClient = useQueryClient();

    const {putEncoded, put} = useApi();

    async function doMutation(pk: number | undefined,
                              data: any): Promise<ICategory> {
        try {
            const response = await put(`/catalogue/categories/${pk}`, data);
            return response.data.data as ICategory;
        } catch (error) {
            throw error;
        }
    }

    return useMutation<ICategory, AxiosError, {
        id: number | undefined;
        data: any
    }, unknown>({
        mutationFn: async ({id, data}) => {
            return await doMutation(id, data);
        },
        onSuccess: async (data) => {
            await queryClient.invalidateQueries({queryKey: ['useApiGetCatalogue']});
            // await queryClient.invalidateQueries({queryKey: ['useApiGetCategory', data.id]});
        },
    });
};


export default useApiUpdateCategory;
